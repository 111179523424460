import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import OrderPlacedDone from "../../../../assets/order-placed-error.png";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    borderRadius: "18px",
  },
};

const OrderDoneText = {
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 10px",
  color: "#181725",
  margin: "10px 0px",
};

const OrderPlacedText = {
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 10px",
  color: "#7C7C7C",
  margin: "10px 0px",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "500",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#D98B3B",
  },
};

const ButtonCartSecond = {
  backgroundColor: "#fff",
  color: "#181725",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#e9e8e8",
  },
};

const OrderFailedModal = () => {
  const [modalIsOpen, setIsOpen] = useState(true);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }
  return (
    <Box>
      <Button
        variant="outlined"
        sx={{ display: "flex", margin: "0 auto" }}
        onClick={openModal}
      >
        Open Failed Order Modal
      </Button>
      <Modal
        open={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <Box>
          <CloseRoundedIcon onClick={closeModal} />
          <img
            src={OrderPlacedDone}
            alt="Order Done"
            className="error-order-icon"
          />
          <Typography sx={OrderDoneText}>Oops! Order Failed</Typography>
          <Typography sx={OrderPlacedText}>
            Something went terribly wrong.
          </Typography>
          <Box
            sx={{
              padding: "0px 10px",
              margin: "30px 0px 10px 0px",
            }}
          >
            <Button sx={ButtonCart}>Please Try Again</Button>
          </Box>
          <Box sx={{ padding: "0px 10px", margin: "10px 0px" }}>
            <Link to="/">
              <Button sx={ButtonCartSecond}>Back to home</Button>
            </Link>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default OrderFailedModal;
