import { EmailOutlined } from "@mui/icons-material";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import LocalPhoneOutlined from "@mui/icons-material/LocalPhoneOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import server from "../../server";
const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  margin: "0px 20px 0px 0px",
};

const CategoryTitle = {
  fontWeight: "600",
  fontFamily: "Quicksand",
  color: "#37474F",
};

const CardMainDiv = {
  padding: "20px 20px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  borderBottom: "1px solid #E1E1E1",
  marginTop: "10px",
  "&.MuiBox-root:hover": {
    background: "rgba(94, 196, 1, 0.17)",
    borderRadius: "12px",
  },
};
const locationTitle = {
  fontSize: "18px",
  fontFamily: "quicksand",
  fontWeight: "600",
};

const addressText = {
  fontSize: "16px",
  fontFamily: "quicksand",
  fontWeight: "500",
  width: "90%",
};

const About = () => {
  const [notifications, setNotifications] = useState([]);
  const navigate = useNavigate();

  const getnotifications = async () => {
    const result = await axios.get(
      `${server}/notifications/user/${localStorage.getItem("user")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setNotifications(result.data.reverse());
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getnotifications();
  }, []);

  useEffect(() => {
    // console.log(notifications)
  }, [notifications]);

  return (
    <Box>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          <Link to="/profile">
            <ArrowBackRoundedIcon />
          </Link>
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          About
        </Typography>
      </Box>
      <Box sx={{ padding: "20px 0" }}>
        <Box sx={{ padding: "0px 10px", borderTop: "solid #E1E1E1" }}>
          <Box sx={CardMainDiv}>
            <Typography sx={locationTitle}>
              <LocationOnOutlinedIcon />
            </Typography>
            <Typography sx={addressText}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#181725",
                }}
              >
                Offline Store
              </Link>
            </Typography>
          </Box>
          <Box sx={CardMainDiv}>
            <Typography sx={locationTitle}>
              <LocalPhoneOutlined />
            </Typography>
            <Typography sx={addressText}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#181725",
                }}
              >
                Phone
              </Link>
            </Typography>
          </Box>
          <Box sx={CardMainDiv}>
            <Typography sx={locationTitle}>
              <EmailOutlined />
            </Typography>
            <Typography sx={addressText}>
              <Link
                style={{
                  textDecoration: "none",
                  color: "#181725",
                }}
              >
                E-mail
              </Link>
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default About;
