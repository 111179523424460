import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCxuEzZxmZaS8TloEFmQ4aE1Fqgqx3Sl3Q",
  authDomain: "agarwal-sales-estore.firebaseapp.com",
  projectId: "agarwal-sales-estore",
  storageBucket: "agarwal-sales-estore.appspot.com",
  messagingSenderId: "576234080218",
  appId: "1:576234080218:web:d02b7a489943e6060022e3",
  measurementId: "G-WGSM2SXGCT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
