import { Box, Grid, Skeleton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import noimage from "../../../../assets/noimage.jpg";
import server from "../../../server";
import CategoryCard from "./CategoryCard";

const ViewCategoryGrid = {
  padding: "1vh 10px",
};

const ViewCategoryGridItem = {
  display: "flex",
  justifyContent: "center",
};

const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // lineHeight: 1,
  //   boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
};

const SubCategorysection = ({ categoryId }) => {
  const [subcategoryList, setSubCategoryList] = useState([]);
  const [loadCount, setLoadCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [catName, setCatName] = useState("");

  // useEffect(() => {
  //   setLoading(true);
  //   const getSubcategories = async () => {
  //     if (categoryId) {
  //       const subcategoryData = await axios.get(
  //         `${server}/subcategory/find/${categoryId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },
  //         },
  //       );
  //       setSubCategoryList(subcategoryData?.data);
  //     }
  //   };

  //   const getCategory = async () => {
  //     if (categoryId) {
  //       const categoryData = await axios.get(
  //         `${server}/category/${categoryId}`,
  //         {
  //           headers: {
  //             Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //           },
  //         },
  //       );
  //       setCatName(categoryData.data.name);
  //     }
  //   };
  //   getSubcategories();
  //   getCategory();
  //   setLoading(false);
  // }, []);

  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true); // Start loading before making API requests

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };

        // Performing both API calls in parallel
        const [subcategoryData, categoryData] = await Promise.all([
          axios.get(`${server}/subcategory/find/${categoryId}`, { headers }),
          axios.get(`${server}/category/${categoryId}`, { headers }),
        ]);

        // Set subcategory and category data after fetching
        setSubCategoryList(subcategoryData?.data);
        setCatName(categoryData?.data.name);
      } catch (error) {
        console.error("Error fetching data: ", error); // Log error for debugging
      } finally {
        setLoading(false); // Stop loading after data is fetched or in case of error
      }
    };

    if (categoryId) {
      getData();
    }
  }, [categoryId]); // Added categoryId as a dependency

  return (
    <>
      {!loading ? (
        <Box
          sx={{
            backgroundColor: "#F2F2F2",
            flexGrow: 1,
            borderRadius: "3px",
            padding: "16px", // Added padding to the box
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              ...ViewCategoryText,
              lineHeight: "1.5",
              paddingBottom: "10px", // Space below title
              fontWeight: 600, // Bold font
            }}
          >
            {categoryId ? catName : "View by category"}
          </Typography>

          {/* Scrollable Category List */}
          <Box
            sx={{
              marginTop: "10px",
              display: "flex",
              flexDirection: "row",
              overflowX: "auto", // Horizontal scrolling
              paddingBottom: "10px",
              scrollbarWidth: "none", // Hide scrollbar
              "&::-webkit-scrollbar": {
                display: "none", // For Chrome/Safari
              },
            }}
          >
            {subcategoryList?.slice(0, 6).map((currcategory, i) => {
              const imageUrlToUse =
                currcategory.imageUrl && currcategory.imageUrl !== ""
                  ? currcategory.imageUrl
                  : noimage;
              return (
                currcategory.imageUrl !== "noimage" && (
                  <Box
                    key={i}
                    sx={{
                      minWidth: "200px",
                      marginRight: "16px", // Spacing between cards
                      padding: "10px",
                      backgroundColor: "#fff", // White background
                      boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)", // Shadow
                      borderRadius: "8px", // Rounded corners
                      textAlign: "center", // Center text under the card
                      transition: "transform 0.2s ease-in-out", // Hover effect
                      "&:hover": {
                        transform: "scale(1.05)", // Scale on hover
                      },
                    }}
                  >
                    {/* CategoryCard Component */}
                    <CategoryCard
                      categoryname={currcategory.name}
                      categoryid={currcategory._id}
                      categoryimage={imageUrlToUse}
                      propid={categoryId}
                      key={i}
                      setLoadCount={setLoadCount}
                    />
                    {/* Category name below card */}
                    <Typography
                      variant="body2"
                      sx={{
                        marginTop: "10px", // Space above category name
                        fontWeight: 500,
                        color: "#333", // Darker text color
                      }}
                    >
                      {currcategory.name}
                    </Typography>
                  </Box>
                )
              );
            })}
          </Box>
        </Box>
      ) : (
        // Loading state (with Skeletons)
        <Grid container spacing={2} sx={ViewCategoryGrid}>
          {[1, 2, 3].map((i) => (
            <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
              <Skeleton
                variant="rectangular" // Rectangular skeleton
                sx={{
                  width: "100%",
                  height: "40vw",
                  borderRadius: "8px", // Rounded skeleton corners
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};

export default SubCategorysection;
