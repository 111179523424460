import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { Box, Button, IconButton, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import server from "../../server";
import ViewReturnCard from "./Layout/ViewReturnCard";

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: "20px",
  backgroundColor: "#fff",
  borderBottom: "1px solid #EAEAEA",
};

const arrowStyle = {
  position: "absolute",
  left: "20px",
};

const CategoryTitle = {
  fontWeight: "500",
  fontFamily: "Poppins",
  fontSize: "14px",
};

const AllProductText = {
  fontWeight: "400",
  fontFamily: "Poppins",
  padding: "8px 20px",
  borderBottom: "1px solid #EAEAEA",
};

const DeliverDiv = {
  padding: "10px 15px",
  borderTop: "8px solid #F2F2F2",
};

const DeliverText = {
  fontSize: "16px",
  fontFamily: "Poppins",
  fontWeight: "600",
};

const DeliveryLocation = {
  fontSize: "18px",
  fontWeight: "400",
  fontFamily: "Poppins",
};

const ChangeText = {
  fontSize: "20px",
  fontWeight: "700",
  fontFamily: "Poppins",
  color: "#CD7F32",
};

const addressText = {
  padding: "0px 0px 0px 20px",
  fontSize: "17px",
  fontWeight: "500",
  fontFamily: "Poppins",
};

const NoteText = {
  fontSize: "15px",
  fontWeight: "600",
  fontFamily: "Poppins",
};

const TotalDivTotal = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "10px 0px",
};

const TotalTotal = {
  fontSize: "19px",
  fontWeight: "400",
  fontFamily: "Poppins",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "10px 10px",
  fontSize: "18px",
  fontWeight: "400",
  width: "100%",
  fontFamily: "Poppins",
  "&.MuiButtonBase-root:hover": {
    background: "#D98B3B",
  },
};

const ViewReturnOrder = () => {
  const { returnId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  //states
  const [returninfo, setreturninfo] = useState([]);
  const [addressinfo, setaddress] = useState([]);
  // const [products, setproducts] = useState([])

  const getReturnDetails = async () => {
    const result = await axios.get(`${server}/returns/${returnId}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setreturninfo(result.data);

    const address_result = await axios.get(
      `${server}/address/find/${result.data.pickUpAddress}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setaddress(address_result.data[0]);
    // console.log(address_result.data)
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getReturnDetails();
  }, []);

  useEffect(() => {
    // console.log(returninfo.variantList)
  }, [returninfo, addressinfo]);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Box sx={header}>
        <Box sx={arrowStyle}>
          {/* <Link to="/client/orders"> */}
          <IconButton
            onClick={(e) => {
              e.preventDefault();
              navigate("/client/orders", {
                state: { value: location?.state?.value },
              });
            }}
          >
            <ArrowBackRoundedIcon />
          </IconButton>
          {/* </Link> */}
        </Box>
        <Typography variant="h6" sx={CategoryTitle}>
          Return Id #{returninfo.returnId}
        </Typography>
      </Box>
      {returninfo.productList &&
        returninfo.productList.map((currproduct, i) => {
          return (
            <ViewReturnCard
              returnprodid={currproduct.productId}
              quantity={currproduct.count}
              variant={currproduct.variant}
              key={i + 1}
            />
          );
        })}
      <Box sx={DeliverDiv}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={DeliveryLocation}>Delivery Location</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            margin: "10px 0px",
          }}
        >
          <LocationOnOutlinedIcon
            style={{
              color: "#37474F",
              backgroundColor: "rgba(35, 108, 217, 0.121039)",
              padding: "10px",
              borderRadius: "50%",
            }}
          />

          <Typography sx={addressText}>
            {returninfo?.orderId?.addressLine}
          </Typography>
        </Box>
      </Box>
      <Box sx={DeliverDiv}>
        <Box sx={TotalDivTotal}>
          <Typography sx={TotalTotal}>Return Amount</Typography>
          <Typography sx={TotalTotal}>
            ₹{returninfo.returnAmount?.toFixed(2)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{ padding: "0px 10px" }}>
        <Link to={`/trackorder/${returnId}`}>
          <Button
            sx={ButtonCart}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/trackorder/${returnId}`, {
                state: { value: location?.state?.value },
              });
            }}
          >
            Track Order
            <ArrowForwardRoundedIcon
              sx={{ position: "absolute", right: "20px" }}
            />
          </Button>
        </Link>
      </Box>{" "}
    </Box>
  );
};

export default ViewReturnOrder;
