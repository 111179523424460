import { Box, Modal, Typography } from "@mui/material";

const DeleteConfirmModal = ({
  openConfirm: open1,
  closeConfirm: handleClose,
  deleteBanner,
}) => {
  return (
    <>
      {" "}
      <Modal
        open={open1}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Are you Sure, You want to delete the banner?
          </Typography>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <button
              variant="contained"
              color="primary"
              className="create-new-post-button"
              style={{
                margin: "10px 20px",
                // backgroundColor: "#CD7F32",
              }}
              onClick={deleteBanner}
            >
              Delete
            </button>
            <button
              variant="contained"
              color="primary"
              className="create-new-post-button"
              style={{
                margin: "10px 20px",
                // backgroundColor: "#CD7F32",
              }}
              onClick={handleClose}
            >
              Cancel
            </button>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default DeleteConfirmModal;
