import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Search } from "../../../../assets/search.svg";

const InputPropsStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none!important",
    },
    input: {
      "&::placeholder": {
        color: "#CD7F32 !important",
        fontSize: "14px",
        fontFamily: "poppins",
        fontWeight: 400,
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
      },
    },
  },
  startAdornment: (
    <InputAdornment position="start">
      <Search />
    </InputAdornment>
  ),
};

const SearchInputStyle = {
  backgroundColor: "#F5F5F5",
  borderRadius: "8px 8px 0px 0px",
  width: "100%",
  margin: "10px 0px",
};

const InputLabelProps = {
  sx: {
    color: "#CD7F32",
    textTransform: "capitalize",
  },
};

const Header = () => {
  const navigate = useNavigate();

  const handleSearch = () => {
    navigate("/search");
  };

  return (
    <Box sx={{ backgroundColor: "#CD7F32", padding: "10px" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0px 10px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            color: "#FFFFFF",
            fontFamily: "Poppins",
          }}
        >
          Agarwal Sales
        </Typography>
      </Box>
      <TextField
        size="small"
        placeholder="Search"
        sx={SearchInputStyle}
        InputProps={InputPropsStyle}
        InputLabelProps={InputLabelProps}
        onClick={handleSearch}
      />
    </Box>
  );
};

export default Header;
