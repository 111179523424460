import CloseIcon from "@mui/icons-material/Close";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

const ProductCard = ({ product, onAddToCart, onRemove }) => {
  return (
    <Card
      sx={{
        display: "flex",
        maxWidth: 300,
        margin: "auto",
        boxShadow: 5,
      }}
    >
      <CardMedia
        component="img"
        sx={{ width: 100 }}
        image={product.imageUrl}
        alt={product.name}
      />
      <Box sx={{ display: "flex", flexDirection: "column", flexGrow: 1 }}>
        <CardContent
          sx={{
            flex: "1 0 auto",
            padding: "8px",
            "&:last-child": { paddingBottom: "8px" },
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1,
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
              }}
            >
              {product.name}
            </Typography>
            <IconButton
              aria-label="close"
              onClick={() => onRemove()}
              size="small"
              sx={{ paddingTop: 0 }}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              marginBottom: 1,
              textAlign: "left",
            }}
          >
            {product.description}
          </Typography>
          <Typography variant="body2" component="div" textAlign="left">
            {product.price} Rs
          </Typography>
        </CardContent>
        <Box sx={{ display: "flex", alignItems: "center", pl: 1, pb: 1 }}>
          <Button size="small" color="primary" onClick={(e) => onAddToCart(e)}>
            Add to Cart
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default ProductCard;
