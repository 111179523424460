import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Backdrop,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import server from "../../../server";
import Loader from "../../Loader/loader";
import DraggableRow from "./DraggableRow";

const CategoryTable = ({
  EnhancedTableToolbar,
  EnhancedTableHead,
  TablePaginationActions,
  selected,
  categories,
  setCategories,
  dense,
  order,
  orderBy,
  handleSelectAllClick,
  handleRequestSort,
  page,
  rowsPerPage,
  handleChangePage,
  handleChangeRowsPerPage,
  handleStatus,
  setImageFile,
  setOldcategory,
  setEdit,
  handleOpen,
  isSequenceCategory,
  getCategoriesBySequence,
  getCategories,
}) => {
  const [sequenceLoading, setSequenceLoading] = useState(false);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, categories.length - page * rowsPerPage);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = categories.findIndex((item) => item._id === active.id);
      const newIndex = categories.findIndex((item) => item._id === over.id);

      const updatedItems = arrayMove(categories, oldIndex, newIndex);

      // Optimistically update the UI
      setCategories(updatedItems);

      // Call API to update the sequence
      updateCategorySequence(updatedItems, categories);
    }
  };

  const updateCategorySequence = async (updatedItems, originalItems) => {
    try {
      setSequenceLoading(true);

      const response = await axios.patch(
        `${server}/admin/category/update-category-sequence`,
        {
          updatedItems,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSequenceLoading(false);
      if (response.status === 200) {
        getCategoriesBySequence();
        getCategories();
        toast.success("Sequence updated successfully");
      }
    } catch (error) {
      console.log("error is ", error);
      toast.error("Error updating sequence", error);
      setSequenceLoading(false);

      // Revert to original state if API call fails
      setCategories(originalItems);
    }
  };
  const handleDeleteCategory = async (categoryId) => {
    try {
      setSequenceLoading(true);

      const response = await axios.patch(
        `${server}/admin/category/removecategoryfromsquence`,
        {
          categoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSequenceLoading(false);
      if (response.status === 200) {
        getCategoriesBySequence();
        getCategories();
        toast.success("Category Removed !");
      }
    } catch (error) {
      console.log("error is ", error);
      toast.error("Error updating sequence", error);
      setSequenceLoading(false);
    }
  };
  return (
    <Box>
      <Backdrop sx={{ color: "white", zIndex: "1201" }} open={sequenceLoading}>
        <Loader />
      </Backdrop>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          rows={categories}
          setRows={setCategories}
          orirows={[]}
        />
        <TableContainer style={{ padding: "0px 20px" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            {isSequenceCategory ? (
              <SortableContext
                items={categories.map((item) => item._id)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={categories?.length}
                  />
                  <TableBody>
                    {(rowsPerPage > 0
                      ? categories?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                      : categories
                    )?.map((row, index) => (
                      <DraggableRow
                        key={row._id}
                        row={row}
                        index={index}
                        handleDeleteCategory={handleDeleteCategory}
                        handleStatus={handleStatus}
                        setImageFile={setImageFile}
                        setOldcategory={setOldcategory}
                        setEdit={setEdit}
                        handleOpen={handleOpen}
                        isSequenceCategory={isSequenceCategory}
                      />
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{ height: (dense ? 33 : 53) * emptyRows }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </SortableContext>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={categories?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? categories?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : categories
                  )?.map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={row._id}
                      style={{ border: "1px solid transparent" }}
                    >
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row._id}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {row.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        <img
                          src={
                            row.imageUrl === "noimage"
                              ? DefaultImage
                              : row.imageUrl
                          }
                          alt=""
                          style={{ width: "50px" }}
                        />
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="left"
                        style={{ border: "1px solid transparent" }}
                      >
                        {format(
                          new Date(row.createdAt),
                          "dd MMMM yyyy - hh:mm a",
                        )}
                      </TableCell>
                      <TableCell style={{ border: "1px solid transparent" }}>
                        <select
                          defaultValue={row?.published}
                          className="status-published"
                          onChange={(e) =>
                            handleStatus(e.target.value, row._id)
                          }
                        >
                          <option value="true">Published</option>
                          <option value="false">Unpublished</option>
                        </select>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{ border: "1px solid transparent" }}
                      >
                        <button
                          className="edit-button"
                          onClick={(e) => {
                            e.preventDefault();
                            setImageFile(row.imageUrl);
                            setOldcategory(row);
                            setEdit(true);
                            handleOpen();
                          }}
                        >
                          Edit
                        </button>
                      </TableCell>
                    </TableRow>
                  ))}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </DndContext>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          component="div"
          count={categories.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};

export default CategoryTable;
