import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
// import SearchIcon from "@mui/icons-material/Search";
import { Backdrop, TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Modal from "@mui/material/Modal";
import { alpha, useTheme } from "@mui/material/styles";
import Tab from "@mui/material/Tab";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";
import ImageUpload from "../../Inventory/Layout/ImageUpload";
import Loader from "../../Loader/loader";
import CategoryForm from "./CategoryForm";
import CategoryTable from "./CategoryTable";

const topRightToastStyles = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const headCells = [
  {
    id: "categoryid",
    numeric: false,
    disablePadding: true,
    label: "Category Id",
  },
  {
    id: "categoryname",
    numeric: false,
    disablePadding: true,
    label: "Category Name",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  {
    id: "date",
    numeric: true,
    disablePadding: false,
    label: "Created Date/Time",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      console.log(props.orirows);
      const filteredRows = await props.orirows.filter((row) => {
        return row.first_name
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Categories
          </Typography>
        )}
      </div>

      {/* <div>
        <TextField
          style={{
            width: "240px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton onClick={handlesearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          placeholder="Search"
          // onChange={handlesearch}
        />
      </div> */}
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const CategoryList = (props) => {
  //rows are basically your data
  const navigate = useNavigate();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [active_users, setActive_users] = useState(0)
  const [categories, setCategories] = useState([]);
  const [orirows, setorirows] = useState([]);
  const [categoriesBySequence, setCategoriesBySequence] = useState([]);
  const [oriSequenceRows, setOriSequenceRows] = useState({});
  //edit and add category  modal states
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState(false);

  const [oldcategory, setOldcategory] = useState();
  const [newcategoryname, setnewcategoryname] = useState();
  const [imageFile, setImageFile] = useState();
  const [value, setValue] = useState(0);
  const [categoryFormOpen, setCategoryFormOpen] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [sequenceLoading, setSequenceLoading] = useState(false);

  const handleOpenDialog = () => {
    setCategoryFormOpen(true);
    // Fetch and set categories data from API
  };

  const handleCloseDialog = () => {
    setCategoryFormOpen(false);
  };

  const handleStatus = async (value, id) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    setLoading(true);
    // const newProduct = { "status": value }

    // navigate("/content")
    //api to change status
    // console.log(id)
    try {
      const updatestatus = await axios.put(
        `${server}/admin/category/update/${id}`,
        {
          published: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      // functionchange()
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    // fetchproducts();
    // setStatus({ ...status, [key]: value })
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setEdit(false);
    setOldcategory();
    setImageFile();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [address, setaddress] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories?.length) : 0;

  const getCategories = async () => {
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setCategories(result.data);
    setorirows(result.data);
  };

  const getCategoriesBySequence = async () => {
    const result = await axios.get(
      `${server}/admin/category/sequencecategories`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    setCategoriesBySequence(result.data);
    setOriSequenceRows(result.data);
    console.log("result data is ", result.data);
  };

  const updateCategoriesSquence = async () => {
    try {
      setSequenceLoading(true);
      console.log("validateion ", selectedCategories);
      if (selectedCategories.length > 0) {
        const result = await axios.patch(
          `${server}/admin/category/sequencecategories`,
          {
            categoryIds: selectedCategories,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
        getCategoriesBySequence();
      }

      setSequenceLoading(false);
    } catch (error) {
      // Handle different types of errors
      if (error.response) {
        console.error("Server responded with an error:", error.response.data);
        toast.error(`Error: ${error.response.data.message}`);
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No response received:", error.request);
        toast.error("No response from server.");
      } else {
        // Something happened in setting up the request that triggered an Error
        console.error("Error setting up the request:", error.message);
        toast.error(`Request error: ${error.message}`);
      }
      setSequenceLoading(false);
    }
  };

  const handleSelectCategories = (selected) => {
    // Create a new array with only the _id values from the selected array
    const selectedIds = selected.map((category) => category._id);
    console.log("map ", selectedIds);
    // Set the state with the new array
    setSelectedCategories(selectedIds);
    console.log("selected ", selectedIds);
  };

  const CreateCategory = async () => {
    const result = await axios.post(
      `${server}/admin/category/create`,
      {
        name: newcategoryname,
        imageUrl: imageFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    console.log(result);
    setImageFile();
    handleClose();

    getCategories();
  };

  const UpdateCategory = async (id) => {
    const result = await axios.put(
      `${server}/admin/category/update/${id}`,
      {
        name: newcategoryname ? newcategoryname : oldcategory.name,
        imageUrl: imageFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    console.log(result);
    setImageFile();
    handleClose();

    getCategories();
  };

  useEffect(() => {
    getCategories();
    getCategoriesBySequence();
  }, []);

  useEffect(() => {}, [categories, orirows]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCategoriesBySequence();
  }, []);

  useEffect(() => {
    if (selectedCategories.length > 0) {
      updateCategoriesSquence();
    }
  }, [selectedCategories]);

  return (
    <>
      <Backdrop sx={{ color: "white", zIndex: "1201" }} open={sequenceLoading}>
        <Loader />
      </Backdrop>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            // height: "80vh"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDIrection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {" "}
              {edit ? <> Edit Category </> : <> Create New Category</>}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <ImageUpload
                newProduct={[]}
                // setNewProduct={}
                setImageFile={setImageFile}
                imagesSave={typeof imageFile === "string" ? imageFile : ""}
                isEdit={false}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                aligntems: "center",
                width: "50%",
                height: "100%",
              }}
            >
              <TextField
                // value={ newproductprice}
                // className="product-input1"
                label="Category Name"
                // defaultValue={oldcategory && oldcategory.name}
                value={oldcategory && oldcategory.name}
                // variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#EFF1F9",
                  marginBottom: "10px",
                }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  setnewcategoryname(e.target.value);
                }}
                // onChange={(e) => e.preventDefault()}
                // disabled
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#5570F1",
                  color: "white",
                  fontWeight: "500",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  console.log("click");
                  if (edit) UpdateCategory(oldcategory._id);
                  else CreateCategory();
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Categories" {...a11yProps(0)} />
              <Tab label="Home Page Categories" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                sx={{
                  marginBottom: "10px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  handleOpen();
                  // navigate('/subcategorylist')
                }}
              >
                Create
              </Button>
            </Box>
            <CategoryTable
              EnhancedTableToolbar={EnhancedTableToolbar}
              EnhancedTableHead={EnhancedTableHead}
              TablePaginationActions={TablePaginationActions}
              selected={selected}
              categories={categories}
              setCategories={setCategories}
              dense={dense}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleStatus={handleStatus}
              setImageFile={setImageFile}
              setOldcategory={setOldcategory}
              setEdit={setEdit}
              handleOpen={handleOpen}
            />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="outlined"
                color="primary"
                onClick={handleOpenDialog}
                sx={{
                  marginBottom: "10px",
                }}
              >
                Add
              </Button>
            </Box>

            <CategoryForm
              open={categoryFormOpen}
              onClose={handleCloseDialog}
              categories={categories}
              onSelect={handleSelectCategories}
              limit={categoriesBySequence.length}
            />
            <CategoryTable
              EnhancedTableToolbar={EnhancedTableToolbar}
              EnhancedTableHead={EnhancedTableHead}
              TablePaginationActions={TablePaginationActions}
              selected={selected}
              categories={categoriesBySequence}
              setCategories={setCategoriesBySequence}
              dense={dense}
              order={order}
              orderBy={orderBy}
              handleSelectAllClick={handleSelectAllClick}
              handleRequestSort={handleRequestSort}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              handleStatus={handleStatus}
              setImageFile={setImageFile}
              setOldcategory={setOldcategory}
              setEdit={setEdit}
              handleOpen={handleOpen}
              isSequenceCategory={true}
              getCategoriesBySequence={getCategoriesBySequence}
              getCategories={getCategories}
            />
          </CustomTabPanel>
        </Box>
      </Box>
    </>
  );
};

export default CategoryList;
