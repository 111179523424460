import CloseIcon from "@mui/icons-material/Close";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import { TextField } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Modal from "@mui/material/Modal";
import Paper from "@mui/material/Paper";
import { alpha, useTheme } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import DefaultImage from "../../../../assets/defaultimg.jpg";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";
import ImageUpload from "../../Inventory/Layout/ImageUpload";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

// function descendingComparator(a, b, orderBy) {
//   if (b[orderBy] < a[orderBy]) {
//     return -1;
//   }
//   if (b[orderBy] > a[orderBy]) {
//     return 1;
//   }
//   return 0;
// }

// function getComparator(order, orderBy) {
//   return order === "desc"
//     ? (a, b) => descendingComparator(a, b, orderBy)
//     : (a, b) => -descendingComparator(a, b, orderBy);
// }

// function stableSort(array, comparator) {
//   const stabilizedThis = array.map((el, index) => [el, index]);
//   stabilizedThis.sort((a, b) => {
//     const order = comparator(a[0], b[0]);
//     if (order !== 0) {
//       return order;
//     }
//     return a[1] - b[1];
//   });
//   return stabilizedThis.map((el) => el[0]);
// }

const headCells = [
  {
    id: "brandid",
    numeric: false,
    disablePadding: true,
    label: "Brand Id",
  },
  {
    id: "brandname",
    numeric: false,
    disablePadding: true,
    label: "Brand Name",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Image",
  },
  //   {
  //     id: "date",
  //     numeric: true,
  //     disablePadding: false,
  //     label: "Created Date/Time",
  //   },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "edit",
    numeric: true,
    disablePadding: false,
    label: "Edit",
  },
];

//this is the header of table basically showing names of the columns and the sort buttons for each
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead style={{ borderTop: "1px solid #E1E2E9" }}>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

//this is for search functionality in table
function EnhancedTableToolbar(props) {
  const { numSelected } = props;

  const handlesearch = async (e) => {
    // const search_data=[];
    if (e.target.value != "") {
      // console.log(e.target.value);
      console.log(props.orirows);
      const filteredRows = await props.orirows.filter((row) => {
        return row.first_name
          ?.toLowerCase()
          ?.includes(e.target.value.toLowerCase());
      });
      // setRows(filteredRows);
      // console.log(filteredRows);
      props.setRows(filteredRows);
    } else props.setRows(props.orirows);
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Brands
          </Typography>
        )}
      </div>

      <div>
        <TextField
          style={{
            width: "240px",
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment>
                <IconButton onClick={handlesearch}>
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          size="small"
          placeholder="Search"
          // onChange={handlesearch}
        />
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const BrandList = (props) => {
  console.log("props", props);
  //rows are basically your data
  const navigate = useNavigate();
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  // const [active_users, setActive_users] = useState(0)
  const [orirows, setorirows] = useState([]);
  const [brands, setBrands] = useState([]);

  //edit and add category  modal states
  const [open, setOpen] = useState(false);
  const [edit, setedit] = useState(false);

  const [oldbrand, setoldbrand] = useState();
  const [newbrandname, setnewbrandname] = useState();
  const [imageFile, setImageFile] = useState();

  const handleStatus = async (value, id) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    setLoading(true);
    // const newProduct = { "status": value }

    // navigate("/content")
    //api to change status
    // console.log(id)
    try {
      const updatestatus = await axios.put(
        `${server}/admin/brand/update/${id}`,
        {
          published: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      // functionchange()
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    // fetchproducts();
    // setStatus({ ...status, [key]: value })
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setedit(false);
    setoldbrand();
    setImageFile();
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = props.rows.map((n) => n.name);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const [address, setaddress] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - brands?.length) : 0;

  const getBrands = async () => {
    const result = await axios.get(`${server}/brands`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // setCategories(result.data);
    setorirows(result.data);
    setBrands(result.data);
  };

  const CreateBrand = async () => {
    console.log("brandname is ", newbrandname);
    const result = await axios.post(
      `${server}/brands/create`,
      {
        name: newbrandname,
        image: imageFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    console.log(result);
    setImageFile();
    handleClose();

    getBrands();
  };

  const UpdateBrand = async (id) => {
    const result = await axios.put(
      `${server}/brands/update/${id}`,
      {
        name: newbrandname ? newbrandname : oldbrand.name,
        imageUrl: imageFile,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    console.log(result);
    setImageFile();
    handleClose();

    getBrands();
  };

  useEffect(() => {
    getBrands();
  }, []);

  useEffect(() => {}, [brands, orirows]);

  return (
    <>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
            // height: "80vh"
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDIrection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              {" "}
              {edit ? <> Edit Brand </> : <> Create New Brand</>}
            </Typography>
            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box sx={{ width: "80%" }}>
              <ImageUpload
                newProduct={[]}
                // setNewProduct={}
                setImageFile={setImageFile}
                imagesSave={typeof imageFile === "string" ? imageFile : ""}
                isEdit={false}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                aligntems: "center",
                width: "50%",
                height: "100%",
              }}
            >
              <TextField
                // value={ newproductprice}
                // className="product-input1"
                label="Brand Name"
                // defaultValue={oldcategory && oldcategory.name}
                value={oldbrand && oldbrand.name}
                // variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#EFF1F9",
                  marginBottom: "10px",
                }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  setnewbrandname(e.target.value);
                  setoldbrand({ ...oldbrand, name: e.target.value });

                  //   console.log("oldbrand", oldbrand);
                }}
                // onChange={(e) => e.preventDefault()}
                // disabled
              />
              <Button
                variant="contained"
                color="primary"
                sx={{
                  backgroundColor: "#5570F1",
                  color: "white",
                  fontWeight: "500",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  console.log("click");
                  if (edit) UpdateBrand(oldbrand._id);
                  else CreateBrand();
                }}
              >
                Submit
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
        <div style={{ display: "flex", justifyContent: "right" }}>
          <Button
            variant="contained"
            color="primary"
            sx={
              {
                // textTransform: "none",
                // backgroundColor: "#5570F1",
                // color: "#FFFFFF",
                // fontWeight: 500,
                // fontSize: "20px",
                // padding: "10px",
                // paddingLeft: "40px",
                // paddingRight: "40px",
                // borderRadius: "15px",
                //   marginBottom: "10vh",
                // "&:hover": {
                //   //you want this to be the same as the backgroundColor above
                //   backgroundColor: "#5570F1",
                // },
              }
            }
            onClick={(e) => {
              e.preventDefault();
              handleOpen();
              // navigate('/subcategorylist')
            }}
          >
            Add New Brand
          </Button>
        </div>
        <Box>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <EnhancedTableToolbar
              numSelected={selected.length}
              rows={brands}
              setRows={setBrands}
              orirows={[]}
            />
            <TableContainer style={{ padding: "0px 20px" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={brands?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? brands?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : brands
                  )?.map((row, index) => {
                    // const isItemSelected = isSelected(row.name);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    // if(row?.is_active=="true")
                    // props.setActive_users(props.active_users+1);

                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        // aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row._id}
                        // selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {" "}
                        {console.log("row is ", row)}
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row._id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <img
                            src={
                              row.image === "noimage" ? DefaultImage : row.image
                            }
                            alt=""
                            style={{ width: "50px" }}
                          />
                        </TableCell>
                        {/* <TableCell
                          padding="none"
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {format(
                            new Date(row.createdAt),
                            "dd MMMM yyyy - hh:mm a",
                          )}
                        </TableCell> */}
                        <TableCell
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <select
                            defaultValue={row?.published}
                            className="status-published"
                            onChange={(e) =>
                              handleStatus(e.target.value, row._id)
                            }
                          >
                            <option value={"true"}>Published</option>
                            <option value={"false"}>Unpublished</option>
                          </select>
                        </TableCell>
                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {/* <Link to="/editproduct" state={{ id: row.id, data: row, isEdit: true }}> */}
                          <button
                            className="edit-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setImageFile(row.imageUrl);
                              setoldbrand(row);

                              // setopen(true)
                              setedit(true);
                              handleOpen();
                            }}
                          >
                            Edit
                          </button>
                          {/* </Link> */}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50]}
              component="div"
              count={brands?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </Paper>
        </Box>
      </Box>
    </>
  );
};

export default BrandList;
