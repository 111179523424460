import { Box } from "@mui/material";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DashboardContext from "../Context/DashboardContext";
import AllOrders from "./Layout/AllOrders";
import Marketing from "./Layout/Marketing";
import Sales from "./Layout/Sales";

const Dashboard = () => {
  const context = useContext(DashboardContext);
  const navigate = useNavigate();

  const {
    customers,
    getCustomers,
    orders,
    getOrders,
    getProducts,
    products,
    posts,
    getPosts,
    returns,
    getReturns,
  } = context;

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else {
      getCustomers();
      getOrders();
      getProducts();
      getReturns();
    }
  }, []);

  useEffect(() => {}, [customers, orders, products]);

  return (
    <Box>
      <Box className="dashboard-main">
        <Box className="">
          <Sales
            orders={orders}
            returns={returns}
            customerCount={customers?.length}
          />
        </Box>
        <Box>
          <Box>
            <Box className="allorders">
              <AllOrders orders={orders} type={"Orders"} />
            </Box>
            <Box className="marketing tile">
              <Marketing orders={orders} label={"Orders"} />
            </Box>
          </Box>

          <Box>
            <Box className="allorders">
              <AllOrders orders={returns} type={"Returns"} />
            </Box>
            <Box className="marketing tile">
              <Marketing orders={returns} label={"Returns"} />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
