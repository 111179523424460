import { Grid, Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import server from "../../../server";
import SubCategorysection from "./SubCategorysection";
import ViewBanner from "./ViewBanner";

const ViewCategoryGrid = {
  padding: "1vh 10px",
};

const ViewCategoryGridItem = {
  display: "flex",
  justifyContent: "center",
};

const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // lineHeight: 1,
  //   boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
};

const ViewCategory = ({}) => {
  const [allCategories, setAllCategories] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);
  const [loading, setLoading] = useState(true);

  // const getbanners = async () => {
  //   setLoading(true);
  //   const result = await axios.get(`${server}/banners`, {
  //     headers: {
  //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //     },
  //   });
  //   // Get the banners after the first 8 objects
  //   const bannersFirst8 = result.data.slice(0, 8);

  //   // Filter the active banners from the sliced array
  //   const activeBanners = bannersFirst8.filter((item) => item.active);
  //   setCategoryBanner(activeBanners);
  //   setLoading(false);
  // };

  // const getCategories = async () => {
  //   setLoading(true);
  //   const result = await axios.get(
  //     `${server}/admin/category/sequencecategories`,
  //     {
  //       headers: {
  //         Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  //       },
  //     },
  //   );
  //   setAllCategories(result?.data);
  //   setLoading(false);
  // };

  // useEffect(() => {
  //   if (!localStorage.getItem("user")) navigate("/");
  //   else getbanners() && getCategories();
  // }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Set loading to true before making API calls
        setLoading(true);

        const headers = {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        };

        // Perform both API requests in parallel using Promise.all
        const [bannersResult, categoriesResult] = await Promise.all([
          axios.get(`${server}/banners`, { headers }),
          axios.get(`${server}/admin/category/sequencecategories`, { headers }),
        ]);

        // Handle banners
        const bannersFirst8 = bannersResult.data.slice(0, 8);
        const activeBanners = bannersFirst8.filter((item) => item.active);
        setCategoryBanner(activeBanners);

        // Handle categories
        setAllCategories(categoriesResult?.data);
      } catch (error) {
        console.error("Error fetching data: ", error);
      } finally {
        // Set loading to false after fetching is done or error occurs
        setLoading(false);
      }
    };

    // Check if user is logged in, then fetch data
    if (!localStorage.getItem("user")) {
      navigate("/");
    } else {
      fetchData();
    }
  }, []);

  const combineArrays = (banners, categories) => {
    let combined = [];
    for (let i = 0; i < 8; i++) {
      if (i < banners.length) {
        combined.push({ type: "banner", data: banners[i] });
      }
      if (i < banners.length) {
        combined.push({ type: "category", data: categories[i] });
      }
    }
    return combined;
  };

  const combinedArray = combineArrays(categoryBanner, allCategories);

  const [loadCount, setLoadCount] = useState(0);
  return (
    <>
      <div>
        {loading ? (
          <>
            <div
              style={{
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
                borderRadius: "3px",
                width: "100%",
                height: "60vw",
              }}
              loading="eager"
            >
              <Skeleton
                style={{
                  width: "100vw",
                  height: "100%",
                }}
              />
            </div>

            <Grid container spacing={2} sx={ViewCategoryGrid}>
              {[1, 2, 3].map((i) => (
                <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
                  <Skeleton
                    style={{
                      width: "40vw",
                      height: "40vw",
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </>
        ) : null}

        {combinedArray.map((item, index) => {
          if (item.type === "banner") {
            return (
              <ViewBanner
                key={`banner-${index}`}
                bannerImg={item?.data?.image}
                catId={item?.data?.categoryId?._id}
                subcatId={item?.data?.subcategoryId?._id}
                prodId={item?.data?.productId?._id}
              />
            );
          } else if (item.type === "category") {
            return (
              <SubCategorysection
                key={`category-${item?.data?._id}`}
                categoryId={item?.data?._id}
              />
            );
          }
          return null;
        })}
      </div>
    </>
  );
};
export default ViewCategory;
