import { Visibility, VisibilityOff } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import {
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { UserContext } from "../../App";
import logo from "../../assets/AgarwalAppLogo.png";
import server from "../server";

const InputField = {
  // backgroundColor: "#FAFAFA",
  width: "100%",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#FAFAFA", // Change this to the desired color
    },
  },
};

const Login = () => {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const [loginDetails, setLoginDetails] = useState({
    name: "",
    password: "",
  });
  const [cust_data, setCust_data] = useState({
    first_name: "",
    last_name: "",
    role: "USER",
    phone: "",
    password: "",
    is_superuser: true,
  });
  const [login, setLogin] = useState(false);
  const handleInput = (key, value) => {
    setLoginDetails({
      ...loginDetails,
      [key]: value,
    });
  };
  const handleInput1 = (key, value) => {
    setCust_data({
      ...cust_data,
      [key]: value,
    });
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (loginDetails.name === "" || loginDetails.password === "") {
        toast.error("Enter valid Login Credentials", {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }
      setLoading(true);
      const result = await axios.post(`${server}/auth/admin/login`, {
        password: loginDetails.password,
        username: loginDetails.name,
      });
      if (result?.data?.message == "eyJ1c2VySWQiOiI2NTgyZD") {
        localStorage.setItem("adminuser", "present");
        localStorage.setItem("user", result.data.user._id);
        localStorage.setItem("accessToken", result.data.accessToken);
        context.setUser(true);
        history.go(0);
        // navigate("/dashboard");
        setLoading(false);
      } else {
        toast.error("Something Went Wrong", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        setLoading(false);
        // console.log(err);
      }
    } catch (err) {
      console.log(err.message);
      setLoading(false);
      toast.error("User Not Found", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  //this function is not used since admin is singular. no backend needed currently for admin login
  // const handleSignup = (e) => {
  // 	console.log(loginDetails, cust_data);
  // 	e.preventDefault();

  // 	axios
  // 		.post(`${server}/auth/user_register`, { cust_data })
  // 		.then((res) => {
  // 			console.log(res);
  // 			axios
  // 				.post(`${server}/auth/login`, loginDetails)
  // 				.then((res) => {
  // 					console.log(res);
  // 					toast.success("Signed up successfully!", {
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: true,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 						theme: "light",
  // 					});
  // 					localStorage.setItem("access", res.data.tokens.access);
  // 					localStorage.setItem(
  // 						"refresh",
  // 						res.data.tokens.refresh
  // 					);
  // 					localStorage.setItem("id", res.data.id);
  // 					localStorage.setItem("adminuser", "present");

  // 					context.setUser(true);
  // 					navigate("/dashboard");
  // 				})
  // 				.catch((err) => {
  // 					toast.error("Something Went Wrong", {
  // 						position: "top-right",
  // 						autoClose: 5000,
  // 						hideProgressBar: true,
  // 						closeOnClick: true,
  // 						pauseOnHover: true,
  // 						draggable: true,
  // 						progress: undefined,
  // 						theme: "light",
  // 					});
  // 					console.log(err);
  // 				});
  // 		})
  // 		.catch((err) => {
  // 			toast.error("Something Went Wrong", {
  // 				position: "top-right",
  // 				autoClose: 5000,
  // 				hideProgressBar: true,
  // 				closeOnClick: true,
  // 				pauseOnHover: true,
  // 				draggable: true,
  // 				progress: undefined,
  // 				theme: "light",
  // 			});
  // 			console.log(err);
  // 		});

  // 	// console.log(signupDetails);
  // };

  useEffect(() => {
    if (localStorage.getItem("adminuser")) navigate("/dashboard");
  }, []);

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        background: "#f4f5fa",
        padding: "0",
        margin: "0",
      }}
    >
      {loading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={loading}
        >
          <CircularProgress color="inherit" />{" "}
        </Backdrop>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          backgroundColor: "#d8d6d6",
        }}
      >
        {/* {login ? ( */}
        <Box className="main-login-div">
          <div style={{ position: "relative" }}>
            <img
              src={logo}
              alt="Company Logo"
              style={{
                height: "100px",
                // width: "auto",
                // position: "absolute",
                // top: "10px",
                // left: "10px",
              }}
            />
            <h1
              style={{
                // position: "absolute",
                // top: "40px",
                // left: "10px",
                fontSize: "12px",
                color: "#333",
                margin: 0,
                textAlign: "center",
              }}
            >
              Since 1978
            </h1>
          </div>
          <h2>Welcome Back!</h2>
          {/* <br /> */}
          <h4 style={{ color: "grey" }}>Login to your account</h4>
          <br />
          <form onSubmit={handleLogin}>
            <TextField
              // label="Email"
              variant="outlined"
              placeholder="Username"
              onChange={(e) => handleInput("name", e.target.value)}
              sx={InputField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <AccountCircleIcon />
                  </InputAdornment>
                ),
              }}
            />
            {/* <input
              className="login-input"
              placeholder="Name"
              value={loginDetails?.name}
              onChange={(e) => handleInput("name", e.target.value)}
              required
            /> */}
            <br />
            <TextField
              // label="Email"
              variant="outlined"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              onChange={(e) => handleInput("password", e.target.value)}
              sx={InputField}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment>
                    <IconButton
                      edge="end"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={(e) => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            {/* <input
              className="login-input"
              type="password"
              placeholder="Password"
              value={loginDetails?.password}
              onChange={(e) => handleInput("password", e.target.value)}
              required
            /> */}
            <br />
            {/* <h5 style={{ color: "grey" }}>Dont't have an account? <span onClick={() => setLogin(false)} style={{ color: "#5570f1", cursor: "pointer" }}>Signup</span></h5> */}

            <div className="login-button-div">
              <button type="submit" className="login-button">
                Login
              </button>
            </div>
          </form>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;
