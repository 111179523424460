import { Close as CloseIcon } from "@mui/icons-material";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Pagination,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

const CategoryForm = ({ open, onClose, categories, onSelect, limit }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5);
  const [selectedCategories, setSelectedCategories] = useState([]);

  useEffect(() => {
    setCurrentPage(1); // Reset to first page when dialog opens
  }, [open]);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const handleSelectCategory = (category) => {
    setSelectedCategories((prevSelected) => {
      const isAlreadySelected = prevSelected.some(
        (item) => item._id === category._id,
      );
      if (isAlreadySelected) {
        return prevSelected.filter((item) => item._id !== category._id);
      }
      if (prevSelected.length < 10 - limit) {
        return [...prevSelected, category];
      }
      return prevSelected;
    });
  };

  const handleSave = () => {
    onSelect(selectedCategories);
    setSelectedCategories([]);
    onClose();
  };

  const paginatedCategories = categories.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage,
  );

  useEffect(() => {
    setCurrentPage(1);
    if (!open) {
      setSelectedCategories([]); // Clear selected categories when dialog is closed
    }
  }, [open]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        <Typography variant="h6">Select Categories</Typography>
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column" gap={2}>
          <List>
            {paginatedCategories.map((category) => (
              <ListItem
                key={category._id}
                sx={{
                  color: selectedCategories.some(
                    (item) => item._id === category._id,
                  )
                    ? "#0A8FDC" // Text color for .new-active
                    : "inherit",
                }}
              >
                <ListItemButton
                  onClick={() => handleSelectCategory(category)}
                  disabled={category.sequence !== 0} // Disable categories with non-zero sequence
                >
                  <ListItemText primary={category.name} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          <span></span>
          <Pagination
            count={Math.ceil(categories.length / itemsPerPage)}
            page={currentPage}
            onChange={handlePageChange}
            color="primary"
          />
          <Typography variant="subtitle1" gutterBottom>
            Selected Categories:
          </Typography>
          <Box
            display="flex"
            flexDirection="column"
            gap={1}
            style={{
              height: "200px",
              overflowY: "auto",
              border: "1px solid #ccc",
              padding: "10px",
              marginBottom: "20px",
            }}
          >
            {selectedCategories.map((category, index) => (
              <Box
                key={category._id}
                sx={{
                  padding: 1,
                  border: "1px solid #ddd",
                  borderRadius: 1,
                  backgroundColor: "#f0f0f0",
                }}
              >
                <Typography variant="body2">
                  {index + 1}. {category.name}
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography variant="body2" color="textSecondary">
            You can only add up to 10 categories.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoryForm;
