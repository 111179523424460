import { Box, Grid, Skeleton } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import server from "../../../server";

const ViewCategoryGrid = {
  padding: "1vh 10px",
};

const ViewCategoryGridItem = {
  display: "flex",
  justifyContent: "center",
};

const ViewCategoryText = {
  padding: "10px",
  backgroundColor: "#fff",
  fontWeight: "600",
  fontFamily: "Quicksand",
  fontSize: "16px",
  // lineHeight: 1,
  //   boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
  borderRadius: "3px",
};

const ViewBanner = ({ bannerImg, catId, subcatId, prodId }) => {
  const [loading, setLoading] = useState(false);
  const [redirctLink, setRedirectLink] = useState();
  const [allCategories, setAllCategories] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);

  const handleDataList = async () => {
    if (catId) {
      setRedirectLink(`/subcategory/${catId}`);
    } else if (prodId) {
      const result = await axios.get(`${server}/products/${prodId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      result.data.product ? setRedirectLink(`/productdetail/${prodId}`) : null;
      // setRedirectLink(`/productdetail/${prodId}`);
    } else if (subcatId) {
      const subcategoryData = await axios.get(
        `${server}/subcategory/${subcatId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      subcategoryData.data
        ? setRedirectLink(
            `/categoryproducts/${subcategoryData.data.name}/${subcategoryData.data._id}/${subcategoryData.data.categoryId}`,
          )
        : null;
    } else {
      setRedirectLink("/");
    }
  };
  useEffect(() => {
    handleDataList();
  }, []);

  return bannerImg ? (
    <Box
      sx={{
        backgroundColor: "#F2F2F2",
        flexGrow: 1,
        borderRadius: "3px",
      }}
    >
      <>
        <div>
          <Link
            // to={`/subcategory/${catId}`}
            to={redirctLink}
            style={{
              textDecoration: "none",
              boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
              borderRadius: "3px",
            }}
            // key={dataList[0]?._id ?? "#"}
          >
            <div
              className="banner_16_9"
              style={{
                backgroundImage: `url(${bannerImg})`,
                boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
                borderRadius: "3px",
              }}
              loading="eager"
            ></div>
          </Link>
          {/* <Typography
              variant="subtitle1"
              sx={{ ...ViewCategoryText, lineHeight: "1.5" }}
              //   key={"subtitle1"}
            >
              {catId ? catname : "View by category"}
            </Typography> */}
        </div>
      </>
    </Box>
  ) : (
    <>
      <div
        style={{
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.5)",
          borderRadius: "3px",
          width: "100%",
          height: "60vw",
        }}
        loading="eager"
      >
        <Skeleton
          style={{
            width: "100vw",
            height: "100%",
          }}
        />
      </div>

      <Grid container spacing={2} sx={ViewCategoryGrid}>
        {[1, 2, 3].map((i) => (
          <Grid item xs={4} key={i} style={ViewCategoryGridItem}>
            <Skeleton
              style={{
                width: "40vw",
                height: "40vw",
              }}
            />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default ViewBanner;
