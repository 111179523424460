import CloseIcon from "@mui/icons-material/Close";
import {
  Backdrop,
  Box,
  Button,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import io from "socket.io-client";
import * as xlsx from "xlsx";
import ExcelIcon from "../../../assets/excelicon.png";
import Plus from "../../../assets/plus.svg";
import UploadCloud from "../../../assets/upload-cloud.svg";
import UploadImage from "../../../assets/upload-image.svg";
import server from "../../server";
import DashboardContext from "../Context/DashboardContext";
import Allproducts from "../Dashboard/Layout/Allproducts";
// import Loader from "../Loader/loader";
import PercentageLoader from "../Loader/PercentageLoader";
import CircularProgressWithLabel from "./Layout/CircularProgressWithLabel";
import InventoryTable from "./Layout/InventoryTable";
import Rating from "./Layout/Rating";
import DownloadSampleExcel from "./Layout/SampleExcel";

// same origin version

const topRightToastStyles = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
};

// let socket;

// if (localStorage.getItem("adminuser")) {
const socket = io(`${server}/admin/sync`, {
  auth: { token: localStorage.getItem("accessToken") },
});
socket.on("connect", () => {
  console.log("Connected to socket!");
});
socket.on("disconnect", () => {
  console.log("Disconnected to socket!");
});
// }

const Inventory = () => {
  const [products, setProducts] = useState([]);

  const [jsonproducts, setJson] = useState();
  const [filename, setfilename] = useState();
  const context = useContext(DashboardContext);

  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState(false);

  const [excelLoading, setExcelLoading] = useState(false);
  const { loading, setLoading } = context;
  const [name, setName] = useState();
  const [category, setCategory] = useState();
  const [subcategory, setSubcategory] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [progress, setProgress] = useState(0);

  const fetchAllCategoriesandSubCategories = async () => {
    try {
      const allCategoriesData = await axios.get(`${server}/category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      const allSubCategoriesData = await axios.get(`${server}/subcategories`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      return {
        categories: allCategoriesData?.data,
        subCategories: allSubCategoriesData?.data,
      };
    } catch (error) {
      console.log("error fetching categories and subcategories", error);
      throw error;
    }
  };

  const checkAndCreateResource = async (
    name,
    type,
    resources,
    additionalData = {},
  ) => {
    try {
      // Check if the resource exists in the local resources array
      let resource;
      if (type === "category" && name !== undefined) {
        resource = resources.find(
          (item) =>
            item.name.trim().toLowerCase() === name.trim().toLowerCase(),
        );

        if (resource) {
          return resource?._id;
        }
      } else if (type === "subcategory" && name !== undefined) {
        resource = resources.find(
          (item) =>
            item.name.trim().toLowerCase() === name.trim().toLowerCase() &&
            item.categoryId === additionalData.categoryId,
        );

        if (resource) {
          return resource?._id;
        }
      }

      // Check if resource exists at server
      // const result = await axios.get(`${server}/check/${type}/${name}`, {
      //   headers: {
      //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      //   },
      // });

      // let data = result.data;
      // if (data && data.length > 0) {
      //   if (type === "subcategory") {
      //     const matchedSubcategory = data.find(
      //       (item) => item.categoryId === additionalData.categoryId,
      //     );
      //     if (!matchedSubcategory) {
      //       toast.error(
      //         `Subcategory with name ${name} does not match the category ID`,
      //       );
      //       setExcelLoading(false);
      //       throw new Error(
      //         `Subcategory with name ${name} does not match the category ID`,
      //       );
      //     }
      //     return matchedSubcategory._id;
      //   }
      //   return data[0]._id; // Assuming the resource has an 'id' field
      // }

      let response;
      if (type == "brands") {
        // Resource does not exist, create it
        response = await axios.post(
          `${server}/${type}/create`,
          {
            name: name,
            ...additionalData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );
      } else if (type == "category" && name !== undefined) {
        // Resource does not exist, create it

        response = await axios.post(
          `${server}/admin/${type}/create`,
          {
            name: name,
            ...additionalData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );

        if (response?.data) {
          resources.push(response.data);
          return response?.data?._id;
        }
      } else if (type == "subcategory" && name !== undefined) {
        // Resource does not exist, create it
        response = await axios.post(
          `${server}/admin/${type}/create`,
          {
            name: name,
            ...additionalData,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
            },
          },
        );

        if (response?.data) {
          resources.push(response.data);
          return response?.data?._id;
        }
      }

      return response?.data?.id; // Assuming the newly created resource returns its 'id'
    } catch (error) {
      console.error(`Error checking or creating ${type}:`, error);
      throw error;
    }
  };

  const requiredColumns = [
    // "brandId",
    "Category",
    "Subcategory",
    "name",
    "price",
    "mrpPrice",
    "stock",
    "sales",
    "description",
    "buyinglimit",
    "stocklimit",
    // "published",
    // "barcode",
  ];

  const validateJsonFormat = (json) => {
    if (json.length === 0) {
      toast.error("Excel file is Empty");
      setExcelLoading(false);
      throw new Error("JSON is empty");
    }

    const keys = Object.keys(json[0]);
    const missingColumns = requiredColumns.filter(
      (column) => !keys.includes(column),
    );

    if (missingColumns.length > 0) {
      toast.error(`Missing required columns: ${missingColumns.join(", ")}`);
      setExcelLoading(false);
      throw new Error(`Missing required columns: ${missingColumns.join(", ")}`);
    }
  };

  const processJson = async (json) => {
    validateJsonFormat(json);
    return json.map((item) => {
      // Rename Category to categoryId
      if (item.Category) {
        item.categoryId = item.Category;
        delete item.Category; // Remove the old Category key
      }

      // Rename Subcategory to subCategoryId
      if (item.Subcategory) {
        item.subCategoryId = item.Subcategory;
        delete item.Subcategory; // Remove the old Subcategory key
      }

      return item;
    });

    return json;
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setJson([]);
    setfilename();

    dataProducts();
  };

  const dataProducts = async () => {
    setLoading(true);
    const result = await axios.get(`${server}/admin/products`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    setProducts(result.data);
    setLoaded(true);
    setLoading(false);
  };

  const UploadImagesInDB = async () => {
    if (jsonproducts.length === 0) {
      toast.error("Please add products!");
      return;
    }

    const result = await axios.put(
      `${server}/admin/updaterwproducts`,
      {
        data: jsonproducts,
      },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      },
    );
    // console.log(result)
    //also call handleclose next and ser json back to empty
    setJson([]);
    handleClose();
  };

  // Function to chunk the array into smaller pieces
  const chunkArray = (array, chunkSize) => {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      result.push(array.slice(i, i + chunkSize));
    }
    return result;
  };

  const sendToServer = async (chunk) => {
    try {
      const result = await axios.post(
        `${server}/products/createmultiple`,
        {
          data: chunk,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      if (!result) {
        throw new Error(`Server error: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error uploading chunk:", error);
    }
  };

  const UploadProducts = async () => {
    if (!jsonproducts || jsonproducts.length === 0) {
      toast.error("Please add products!");
      return;
    }

    setExcelLoading(true);

    try {
      const chunkSize = 20;
      const chunks =
        jsonproducts.length > chunkSize
          ? chunkArray(jsonproducts, chunkSize)
          : [[...jsonproducts]];

      const { categories, subCategories } =
        await fetchAllCategoriesandSubCategories();
      let localCategories = [...categories];
      let localSubCategories = [...subCategories];
      for (let item of jsonproducts) {
        // item.brandId = await checkAndCreateResource(item.brandId, "brands");

        item.categoryId = await checkAndCreateResource(
          item.categoryId,
          "category",
          localCategories,
        );
        item.subCategoryId = await checkAndCreateResource(
          item.subCategoryId,
          "subcategory",
          localSubCategories,
          {
            categoryId: item.categoryId,
          },
        );
      }

      for (let i = 0; i < chunks.length; i++) {
        await sendToServer(chunks[i]);
        setProgress(((i + 1) / chunks.length) * 100);
      }

      toast.success("Products created successfully!");
    } catch (error) {
      console.error("Error:", error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(`Error: ${error.response.data.error}`);
      } else {
        toast.error("An error occurred while creating products.");
      }
    } finally {
      setExcelLoading(false);
    }

    //also call handleclose next and ser json back to empty
    setJson([]);
    handleClose();
  };

  const readUploadFile = (e) => {
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = xlsx.utils.sheet_to_json(worksheet);
        setExcelLoading(true);
        const updatedJson = await processJson(json);
        setExcelLoading(false);
        setJson(updatedJson);
      };
      reader.readAsArrayBuffer(e.target.files[0]);
    }
  };

  const callSyncApi = async () => {
    socket.emit("sync-products");
    setSyncProgress({ progress: 5, msg: "Started sycing products.." });
    return;
    // try {
    //   setLoading(true);
    //   const result = await axios.get(`${server}/admin/sync-with-retailware`, {
    //     headers: {
    //       Authorization: `Bearer ${localStorage.getItem("accessToken")}`, // Attach the token to the Authorization header
    //     },
    //   });
    //   console.log(result);
    // } catch (error) {
    //   console.log(error.message);
    // }
    // setLoading(false);
  };

  const getSyncStaus = () => {
    axios
      .get(`${server}/admin/product_sync_status`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })
      .then((res) => {
        setSyncProgress(res.data);
      })
      .catch((err) => console.log(err));
  };

  const showModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const [syncing, setSyncing] = useState(false);
  const [syncProgress, setSyncProgress] = useState({ progress: 0, msg: "" });

  useEffect(() => {
    if (!localStorage.getItem("adminuser")) navigate("/");
    else if (!loaded) {
      getSyncStaus();
      dataProducts();
    }

    socket.on("sync-products-update", (body) => setSyncProgress(body));

    // return () => {
    //   socket.disconnect();
    // };
  }, []);

  useEffect(() => {
    if (
      syncProgress?.progress == -1 ||
      syncProgress?.msg?.startsWith("Error")
    ) {
      toast.error(
        "Error syncing products RetailWare API may be down!",
        topRightToastStyles,
      ) && console.log(syncProgress);
      setTimeout(() => {
        setSyncing(false);
      }, 5000);
      dataProducts();
    } else if (syncProgress?.progress == 100) {
      toast.success("Products synced!", topRightToastStyles);
      // && socket.disconnect();
      setTimeout(() => {
        setSyncing(false);
      }, 5000);
      dataProducts();
    } else if (syncProgress?.progress > 0) {
      !syncing && setSyncing(true);
    }
  }, [syncProgress]);

  return (
    <div
      style={{
        boxSizing: "border-box",
        padding: "0",
        margin: "0",
      }}
    >
      <Backdrop sx={{ color: "white", zIndex: "1201" }} open={excelLoading}>
        {/* <Loader /> <p>{Math.round(progress)}% uploaded</p> */}
        <PercentageLoader progress={Math.round(progress)} />
      </Backdrop>
      <Modal
        open={open}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ zIndex: "1" }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50vw",
            bgcolor: "background.paper",
            borderRadius: "12px",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: 1,
            }}
          >
            <Typography
              sx={{
                marginBottom: "10px",
                fontWeight: 500,
              }}
            >
              Upload Excel
            </Typography>

            <IconButton
              onClick={(e) => {
                e.preventDefault();
                handleClose();
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="imageUpload-div">
              <div>
                <div className="upload-main-div">
                  <input
                    type="file"
                    id={`image-upload`}
                    // accept="image/*"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      setfilename(event.target.files[0]?.name);
                      readUploadFile(event);
                    }}
                  />
                  {filename ? (
                    <label htmlFor="image-upload">
                      <div>
                        <img
                          alt="upload-img"
                          src={ExcelIcon}
                          className="upload-image"
                        />
                        <div className="upload-cloud-div">
                          <img
                            alt="upload-img"
                            src={UploadCloud}
                            className="upload-cloud"
                          />
                          <p className="upload-text">
                            Succesfully Uploaded Excel
                          </p>
                        </div>
                        <p className="upload-desc">
                          File uploaded - {filename}
                        </p>
                      </div>
                    </label>
                  ) : (
                    <label htmlFor="image-upload">
                      <div>
                        <img
                          alt="upload-img"
                          src={UploadImage}
                          className="upload-image"
                        />
                        <div className="upload-cloud-div">
                          <img
                            alt="upload-img"
                            src={UploadCloud}
                            className="upload-cloud"
                          />
                          <p className="upload-text">Upload Excel</p>
                        </div>
                        <p className="upload-desc">
                          Upload excel for your products.
                        </p>
                        <p className="upload-desc">
                          File Format <span>xlsx</span>
                        </p>
                      </div>
                    </label>
                  )}
                </div>
              </div>
            </div>

            <DownloadSampleExcel />

            <Button
              variant="contained"
              color="primary"
              onClick={(e) => {
                e.preventDefault();
                // UploadImagesInDB();
                UploadProducts();
              }}
              disabled={!jsonproducts || jsonproducts.length == 0}
              sx={{ marginTop: 2 }}
              // sx={{
              //   backgroundColor: "#5570F1",
              //   width: "fit-content",
              //   textDecoration: "none",
              //   color: "#fff",
              // }}
            >
              Submit File
            </Button>
          </Box>
        </Box>
      </Modal>

      <div className="order-head">
        <p className="order-title">Inventory Summary</p>
        {/* <Link
          to="/addproduct"
          state={{id:-1,isEdit: false }}
          style={{ textDecoration: "none" }}
        > */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 24,
          }}
        >
          {/* <p
            className="order-title"
            style={{ display: "flex", alignItems: "center" }}
          >
            {syncProgress?.msg}
          </p> */}
          {syncing && (
            <>
              <CircularProgressWithLabel value={syncProgress?.progress ?? 0} />
            </>
          )}

          {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              callSyncApi();
            }}
          >
           
            Sync Products
          </Button> */}

          {/* <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              showModal();
            }}
          >
            Add Product
          </Button> */}
          <Link
            to="/editproduct"
            state={{
              isEdit: false,
            }}
          >
            <Button variant="contained" color="primary">
              Add Product
            </Button>
          </Link>

          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              handleOpen();
            }}
          >
            <img alt="plus" src={Plus} />
            Upload Excel
          </Button>
        </div>
        {/* </Link> */}
      </div>
      <div className="inventory-grid">
        <div className="allproducts">
          <Allproducts products={products} />
        </div>
        <div className="rating">
          <Rating products={products} />
        </div>
      </div>
      <div>
        <InventoryTable
          products={products}
          fetchproducts={dataProducts}
          setName={setName}
          setCategory={setCategory}
          setSubcategory={setSubcategory}
        />
      </div>
    </div>
  );
};

export default Inventory;
