import {
  closestCenter,
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import {
  Backdrop,
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
} from "@mui/material";
import axios from "axios";
import { format } from "date-fns";
import { useState } from "react";
import { toast } from "react-toastify";
import server from "../../../server";
import Loader from "../../Loader/loader";
import DraggableSubcategory from "./DraggableSubCategory";

const SubCategoryTable = ({
  EnhancedTableHead,
  subcategories,
  setSubCategories,
  categories,
  setselectedCategory,
  selected,
  order,
  orderBy,
  handleSelectAllClick,
  handleRequestSort,
  dense,
  rowsPerPage,
  page,
  handleChangePage,
  handleChangeRowsPerPage,
  handleStatus,
  setImageFile,
  handleOpen,
  setoldsubcategory,
  setedit,
  EnhancedTableToolbar,
  TablePaginationActions,
  isSequenceSubCategory,
  getSubCategories,
}) => {
  const [sequenceLoading, setSequenceLoading] = useState(false);

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, subcategories.length - page * rowsPerPage);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = subcategories.findIndex(
        (item) => item._id === active.id,
      );
      const newIndex = subcategories.findIndex((item) => item._id === over.id);

      const updatedItems = arrayMove(subcategories, oldIndex, newIndex);

      // Optimistically update the UI
      setSubCategories(updatedItems);

      updateSubCategorySequence(updatedItems, subcategories);
    }
  };

  const updateSubCategorySequence = async (updatedItems, originalItems) => {
    try {
      setSequenceLoading(true);

      const response = await axios.patch(
        `${server}/admin/subcategory/update-subcategory-sequence`,
        {
          updatedItems,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSequenceLoading(false);
      if (response.status === 200) {
        getSubCategories();
        toast.success("Sequence updated successfully");
      }
    } catch (error) {
      console.log("error is ", error);
      toast.error("Error updating sequence", error);
      setSequenceLoading(false);
      getSubCategories();
      // Revert to original state if API call fails
      setSubCategories(originalItems);
    }
  };

  const handleDeleteSubcategory = async (subCategoryId) => {
    try {
      setSequenceLoading(true);

      const response = await axios.patch(
        `${server}/admin/subcategory/removesubcategoryfromsequence`,
        {
          subCategoryId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      setSequenceLoading(false);
      if (response.status === 200) {
        getSubCategories();
        toast.success("Category Removed !");
      }
    } catch (error) {
      console.log("error is ", error);
      toast.error("Error updating sequence", error);
      setSequenceLoading(false);
    }
  };
  return (
    <Box>
      <Backdrop sx={{ color: "white", zIndex: "1201" }} open={sequenceLoading}>
        <Loader />
      </Backdrop>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <EnhancedTableToolbar
          numSelected={selected.length}
          rows={subcategories}
          setRows={setSubCategories}
          orirows={[]}
          setselectedCategory={setselectedCategory}
          categories={categories}
        />
        <TableContainer style={{ padding: "0px 20px" }}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            {isSequenceSubCategory ? (
              <SortableContext
                items={subcategories.map((item) => item._id)}
                strategy={verticalListSortingStrategy}
              >
                <Table
                  sx={{ minWidth: 750 }}
                  aria-labelledby="tableTitle"
                  size={dense ? "small" : "medium"}
                >
                  <EnhancedTableHead
                    numSelected={selected.length}
                    order={order}
                    orderBy={orderBy}
                    onSelectAllClick={handleSelectAllClick}
                    onRequestSort={handleRequestSort}
                    rowCount={subcategories?.length}
                  />
                  <TableBody>
                    {(rowsPerPage > 0
                      ? subcategories?.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                      : subcategories
                    )?.map((row, index) => (
                      <DraggableSubcategory
                        key={row._id}
                        row={row}
                        handleDeleteSubcategory={handleDeleteSubcategory}
                        handleStatus={handleStatus}
                        setImageFile={setImageFile}
                        setOldSubcategory={setoldsubcategory}
                        setEdit={setedit}
                        handleOpen={handleOpen}
                        isSequenceSubcategory={isSequenceSubCategory}
                      />
                    ))}
                    {emptyRows > 0 && (
                      <TableRow
                        style={{
                          height: (dense ? 33 : 53) * emptyRows,
                        }}
                      >
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </SortableContext>
            ) : (
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={subcategories?.length}
                />
                <TableBody>
                  {(rowsPerPage > 0
                    ? subcategories?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                    : subcategories
                  )?.map((row, index) => {
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row.name}
                        sx={{ cursor: "pointer" }}
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row._id}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {row.name}
                        </TableCell>
                        <TableCell
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <img
                            src={
                              row.imageUrl === "noimage"
                                ? DefaultImage
                                : row.imageUrl
                            }
                            alt=""
                            style={{ width: "50px" }}
                          />
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="left"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          {format(
                            new Date(row.createdAt),
                            "dd MMMM yyyy - hh:mm a",
                          )}
                        </TableCell>
                        <TableCell
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <select
                            defaultValue={row?.published}
                            className="status-published"
                            onChange={(e) =>
                              handleStatus(e.target.value, row._id)
                            }
                          >
                            <option value={"true"}>Published</option>
                            <option value={"false"}>Unpublished</option>
                          </select>
                        </TableCell>

                        <TableCell
                          padding="none"
                          align="center"
                          style={{
                            border: "1px solid transparent",
                          }}
                        >
                          <button
                            className="edit-button"
                            onClick={(e) => {
                              e.preventDefault();
                              setImageFile(row.imageUrl);
                              setoldsubcategory(row);
                              setedit(true);
                            }}
                          >
                            Edit
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            )}
          </DndContext>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[25, 50]}
          component="div"
          count={subcategories?.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          ActionsComponent={TablePaginationActions}
        />
      </Paper>
    </Box>
  );
};

export default SubCategoryTable;
