import { Backdrop } from "@mui/material";
import Box from "@mui/material/Box";
import axios from "axios";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import server from "../../server";
import ScannerProductCard from "./ScannerProductCard";

function Scanner() {
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState("");
  const [productDetail, setProductDetail] = useState(null);
  const [isProductCard, setIsProductCard] = useState(false);

  const fetchProductByBarcode = async () => {
    const result = await axios.get(`${server}/products/barcode/${scanResult}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    if (result?.data?.length > 0) {
      setProductDetail(result.data[0]);
      setIsProductCard(true);
    } else {
      toast.error("Product NOt Found!");
    }
  };

  const startScanner = () => {
    const scanner = new Html5QrcodeScanner("reader", {
      qrbox: {
        width: 250,
        height: 250,
      },
      fps: 5,
    });

    scanner.render(success, error);

    function success(result) {
      scanner.clear();
      setScanResult(result);
      setIsImage(true);
    }

    function error(err) {
      console.warn(err);
    }

    return () => {
      scanner
        .clear()
        .catch((err) => console.error("Error clearing scanner:", err));
    };
  };

  useEffect(() => {
    if (scanResult == "") {
      const cleanup = startScanner();
      return cleanup;
    }
  }, [scanResult]);

  useEffect(() => {
    if (scanResult) {
      async function fetchData() {
        if (scanResult) {
          await fetchProductByBarcode();
        }
      }
      fetchData();
    }
  }, [scanResult]);

  const handleRemove = () => {
    setIsProductCard(false);
    setScanResult("");
  };

  const handleAddToCart = async (e) => {
    e.preventDefault();
    let arr;
    // handleOpen()
    //using JSON of weights as given on 22 july
    let cartarr = JSON.parse(localStorage.getItem("cart"));
    if (localStorage.getItem("cart")) {
      //check for prexisting product
      const exists = cartarr.some(function (element) {
        if (element[0] == productDetail._id) {
          if (element.length === 3) {
            element[2] = (parseInt(element[2]) + 1).toString();
            element[1] = parseInt(selectedVariant);
            if (selectedVariant >= 100) {
              // divide by 1000
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetail.price * (selectedVariant / 1000),
              );
            } else
              localStorage.setItem(
                "cartprice",
                JSON.parse(localStorage.getItem("cartprice")) +
                  productDetail.price * selectedVariant,
              );
          } else {
            element[1] = (parseInt(element[1]) + 1).toString();
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetail.price,
            );
          }

          return true;
        }
      });
      //increment the quantity

      //update the cart
      if (!exists) {
        let temparr;
        if (productDetail?.subCategoryId?.name?.includes("Loose")) {
          temparr = [productDetail._id, selectedVariant, 1];
          if (selectedVariant >= 100) {
            // divide by 1000
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetail.price * (selectedVariant / 1000),
            );
          } else
            localStorage.setItem(
              "cartprice",
              JSON.parse(localStorage.getItem("cartprice")) +
                productDetail.price * selectedVariant,
            );
        } else {
          temparr = [productDetail._id, 1];
          localStorage.setItem(
            "cartprice",
            JSON.parse(localStorage.getItem("cartprice")) + productDetail.price,
          );
        }
        cartarr.push(temparr);
      }
    }

    localStorage.setItem("cart", JSON.stringify(cartarr));
    navigate("/cart");

    //latest code ends here from 22 july

    toast.success("Added to bag. ", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    // }
  };

  return (
    <div>
      <div id="reader"></div>
      {isProductCard ? (
        <Backdrop sx={{ color: "#fff", zIndex: "1201" }} open={isProductCard}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ScannerProductCard
              product={productDetail}
              onAddToCart={handleAddToCart}
              onRemove={handleRemove}
            />
          </Box>
        </Backdrop>
      ) : null}
    </div>
  );
}

export default Scanner;
