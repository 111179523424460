import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import FilterListRoundedIcon from "@mui/icons-material/FilterListRounded";
import { Fragment, useEffect, useState } from "react";
import CategoryProductCard from "../../CategoryProducts/Layout/CategoryProductCard";

import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import axios from "axios";
import server from "../../../server";
import ProductNotFound from "./ProductNotFound";

const CategoryTitle = {
  fontWeight: "500",
  fontSize: "20px",
  fontFamily: "poppins",
  padding: "10px 0px",
};

const header = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "10px 20px",
};

const RecTitle = {
  fontWeight: "700",
  fontSize: "18px",
  fontFamily: "quicksand",
};

const headerLeft = {
  display: "center",
  alignItems: "center",
};

const InputPropsStyle = {
  sx: {
    fieldset: {
      borderRadius: 0,
      borderWidth: 0,
    },
    "&:focus-within fieldset, &:focus-visible fieldset": {
      border: "none !important",
    },
    input: {
      "&::placeholder": {
        color: "rgba(55, 71, 79, 0.72) !important",
        fontSize: "16px",
        padding: "0px 0px 0px 10px",
        mixBlendMode: "normal",
        opacity: "0.87",
        fontWeight: "500",
        fontFamily: "quicksand",
      },
    },
  },
};

const PriceRangeText = {
  fontSize: "18px",
  fontWeight: "600",
  fontFamily: "quicksand",
  color: "#37474F",
};

const checkAll = {
  fontSize: "12px",
  fontWeight: "600",
  fontFamily: "quicksand",
  backgroundColor: "rgba(54, 179, 126, 0.14)",
  borderRadius: "12px",
  padding: "3px 8px",
  color: "#CD7F32",
};

const categoryItems = {
  fontSize: "16px",
  fontWeight: "500",
  fontFamily: "Poppins",
  // color: "#CD7F32",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "10px",
  fontSize: "18px",
  fontWeight: "600",
  width: "100%",
  fontFamily: "Poppins",
  borderRadius: "8px",
  margin: "20px 0px 10px 0px",
  "&.MuiButtonBase-root:hover": {
    background: "#CD7F32",
  },
  position: "relative",
};

const SearchResult = (props) => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  //states for sidebar
  const [min, setmin] = useState();
  const [max, setmax] = useState();

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === "keydown") {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  //state
  // const [originalProducts, setOriginal] = useState();
  const [showproducts, setShowProducts] = useState([]);

  const [categories, setcategories] = useState([]);
  const [showmin, setshowmin] = useState(false);
  const [showmax, setshowmax] = useState(false);
  const [showfilter, setshowfilter] = useState();
  const [checkall, setall] = useState(false);
  const [checked, setChecked] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const pageSize = 10; // Set your desired page size

  useEffect(() => {
    setshowfilter(
      Boolean(
        checked
          .map((elem, i) => (elem === true ? i : null))
          .filter((op) => op != null)
          .map((index) => categories[index])
          .map(({ name }) => name).length,
      ),
    );
  }, [checked]);

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
        position: "relative",
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "10px 0px",
          padding: "0px 20px",
        }}
      >
        <Typography sx={PriceRangeText}>Price Range</Typography>
        <IconButton onClick={toggleDrawer(anchor, false)}>
          <CloseRoundedIcon />
        </IconButton>
      </Box>
      <FormControl sx={{ width: "100%" }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          sx={{ width: "100%" }}
          // defaultValue="0"
          name="radio-buttons-group"
          onChange={(e) => {
            // console.log(e.target.value)
            // setchoice(e.target.value)
            if (e.target.value === "1") {
              // console.log("high to low")
              setmax(true);
              setmin(null);
            }
            if (e.target.value === "2") {
              // console.log("low to high")
              setmax(null);
              setmin(true);
            }
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>High to Low</Typography>
            <FormControlLabel
              value={1}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
              padding: "10px",
              // borderTop: "2px solid #F0F0F0",
              borderBottom: "2px solid #F0F0F0",
            }}
          >
            <Typography>Low to High</Typography>
            <FormControlLabel
              value={2}
              control={
                <Radio
                  sx={{
                    "&, &.Mui-checked": {
                      color: "rgba(94, 196, 1, 1)",
                    },
                  }}
                />
              }
            />
          </Box>
        </RadioGroup>
      </FormControl>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "20px 0px",
          padding: "0px 20px",
        }}
      >
        <Typography sx={PriceRangeText}>Categories</Typography>
        <Button onClick={(e) => maketrueall(e)}>
          <Typography sx={checkAll}>Check All</Typography>
        </Button>
      </Box>
      <Box>
        {categories &&
          categories.map((currcategory, i) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  margin: "0px 0px",
                  borderTop: "2px solid #F0F0F0",
                  borderBottom: "2px solid #F0F0F0",
                  padding: "20px",
                }}
                key={i}
              >
                <Typography sx={categoryItems}>{currcategory.name}</Typography>
                <Checkbox
                  onClick={(e) => {
                    let temp = [...checked];
                    temp[i] = e.target.checked; //.toString();
                    setChecked(temp);
                  }}
                  checked={checked[i]}
                  icon={<CircleOutlinedIcon />}
                  checkedIcon={<CheckCircleIcon />}
                />
              </Box>
            );
          })}
      </Box>
      <Box
        sx={{
          padding: "0px 10px",
          position: "sticky",
          bottom: "0",
          width: "93%",
        }}
      >
        <Button
          sx={ButtonCart}
          onClick={(e) => {
            setFilter(e);
            setState({ ...state, ["left"]: false });
          }}
        >
          Apply
        </Button>
      </Box>
    </Box>
  );

  const maketrueall = (e) => {
    e.preventDefault();
    // console.log("called")
    setChecked(Array(categories.length).fill(true));
    // checkedarr.fill("true")
    // console.log(checkedarr.length)
    // checkedarr.forEach( (element) =>{
    // console.log(element)})
  };

  const getProducts = async (query = "", page = 1, limit = 20, loadMore) => {
    // setLoading(true);
    setShowProducts([]);

    try {
      const res = await axios.get(
        `${server}/searchproduct?q=${query}&page=${page}&limit=${limit}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      const { result, totalCount } = res.data;
      loadMore
        ? setShowProducts([...showproducts, ...result])
        : setShowProducts([...result]);
      // setOriginal(filteredProducts);
      setTotalCount(totalCount); // Assuming you have a state for totalCount
    } catch (error) {
      console.log(error);
    }

    setLoading(false);
  };

  const getcategories = async () => {
    setLoading(true);
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    setcategories(result.data);
    let temparr = Array(result.data.length).fill(false);
    setChecked(temparr);
    setLoading(false);
  };

  const setFilter = async (e) => {
    if (e) e.preventDefault();

    // console.log(checkedarr)
    setshowfilter([]);
    setshowmin(false);
    setshowmax(false);
    setShowProducts([]);

    // let setuparr = [];
    // let setup = 0;
    // let catnames = [];
    // let checkedarr = checked;

    let order = {};
    if (min !== null || max !== null) {
      order = { price: max === null ? 1 : -1 };
      //   price = { $gte: min, $lte: max };
      // } else if (min !== null) {
      //   price = { $gte: min };
      // } else if (max !== null) {
      //   price = { $lte: max };
    }

    const selectedCats = checked
      .map((elem, i) => (elem === true ? i : null))
      .filter((op) => op != null)
      .map((index) => categories[index]);

    const filterCriteria = {
      categoryId: selectedCats.map(({ _id }) => _id),
      order,
    };

    if (!Object.keys(order).length) delete filterCriteria.order;

    try {
      const result = await axios.post(
        `${server}/filterProducts`,
        filterCriteria,
        {
          params: { page: currentPage, q: props.query },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );

      currentPage == 1
        ? setShowProducts(result.data.docs)
        : setShowProducts([...showproducts, ...result.data.docs]);
      console.log(result.data.docs);
      setshowfilter(selectedCats.map(({ name }) => name));
    } catch (error) {
      console.log(
        "Error filtering products:",
        error?.response?.data || error.message,
      );
    }
    showappliedfilters();
    // setchecked(Array(categories.length).fill("false"));
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [props.query]);

  useEffect(() => {
    setLoading(true);

    checked.filter((option) => option).length
      ? setFilter()
      : getProducts(props.query, currentPage, pageSize, currentPage != 1);

    window.scrollTo(0, document.body.scrollHeight);

    setLoading(false);
  }, [currentPage, props.query, checked]);

  useEffect(() => {
    getcategories();
  }, []);

  const showappliedfilters = () => {
    if (min != null) setshowmin(true);
    if (max != null) setshowmax(true);
  };

  return (
    <Box
      sx={{
        marginBottom: "100px",
        gap: 2,
        display: "flex",
        flexDirection: "column",
      }}
    >
      {showfilter && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "left",
            justifyContent: "space-between",
            padding: 2,
          }}
        >
          {showfilter ? <Box sx={RecTitle}>Applied Filters</Box> : <></>}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // justifyContent: "space-between",
              flexWrap: "wrap",
            }}
          >
            {showmin ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "10px 10px",
                  backgroundColor: "#F0F1F2",
                  height: "fit-content",
                  width: "auto",
                  marginBottom: "10px",
                  borderRadius: "8px",
                }}
              >
                low to high
              </Box>
            ) : (
              <></>
            )}
            {showmax ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                  justifyContent: "center",
                  padding: "10px 10px",
                  backgroundColor: "#F0F1F2",
                  height: "fit-content",
                  width: "auto",
                  borderRadius: "8px",
                }}
              >
                high to low
              </Box>
            ) : (
              <></>
            )}
            {showfilter &&
              showfilter.map((element, i) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginBottom: "10px",
                      marginRight: "10px",
                      marginLeft: "0px",
                      padding: "10px 10px",
                      backgroundColor: "#F0F1F2",
                      height: "fit-content",
                      width: "auto",
                      borderRadius: "8px",
                    }}
                    key={i}
                  >
                    {element}
                  </Box>
                );
              })}
            {showmin || showmax || showfilter?.length > 0 ? (
              <Box
                onClick={(e) => {
                  e.preventDefault();
                  // setShowProducts(originalProducts);
                  setshowfilter();
                  setChecked([]);
                  setmin();
                  setmax();
                  setshowmin(false);
                  setshowmax(false);
                }}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginBottom: "10px",
                  marginRight: "10px",
                  marginLeft: "10px",
                  padding: "10px 10px",
                  backgroundColor: "#F0F1F2",
                  height: "fit-content",
                  width: "auto",
                  borderRadius: "8px",
                }}
              >
                X
              </Box>
            ) : (
              <></>
            )}
          </Box>
        </Box>
      )}

      <Box sx={header}>
        {/* <Box sx={headerLeft}> */}
        <Typography variant="h6" sx={CategoryTitle}>
          Search Result
        </Typography>
        {/* </Box> */}
        {["left"].map((anchor) => (
          <Fragment key={anchor}>
            <IconButton onClick={toggleDrawer(anchor, true)}>
              <FilterListRoundedIcon />
            </IconButton>
            <Drawer
              anchor={anchor}
              open={state[anchor]}
              onClose={toggleDrawer(anchor, false)}
            >
              {list(anchor)}
            </Drawer>
          </Fragment>
        ))}
      </Box>
      {/* <CategoryCarousel bybrand={getbybrand} /> */}
      {showproducts.length > 0 ? (
        <>
          {showproducts &&
            showproducts.map((prod) => {
              return (
                prod.stocklimit < prod.stock && (
                  <CategoryProductCard
                    prodid={prod._id}
                    key={prod._id}
                    productDetails={prod}
                  />
                )
              );
            })}
          {/* <Pagination
						count={Math.ceil(totalCount / pageSize)}
						page={currentPage}
						onChange={(event, page) => {
							setCurrentPage(page);
						}}
						color="primary"
						size="large"
						shape="rounded"
						sx={{ width: "90%", alignSelf: "center", mt: 2 }}
					/> */}
          <Button
            style={{ width: "fit-content", alignSelf: "center" }}
            onClick={() => {
              setCurrentPage((prevPage) => prevPage + 1);
            }}
          >
            Load More
          </Button>
        </>
      ) : currentPage != 1 ? (
        <ProductNotFound
          message={"Oops! We can't find any more products!"}
          loading={loading}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default SearchResult;
