import PrintIcon from "@mui/icons-material/Print";
import { Button } from "@mui/material";
import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactToPrint from "react-to-print";
import server from "../../server";

const PrintOrder = () => {
  const componentRef = useRef();
  const [products, setProducts] = useState([]);
  let orderItems = [];
  const location = useLocation();
  let cartarr = location.state.cartarr;
  // let cartarr = JSON.parse(localStorage.getItem("cart"));

  cartarr.forEach((element) => {
    if (element.length == 3) {
      if (parseInt(element[2]) > 0) {
        orderItems.push({
          productId: element[0],
          itemCount: element[2],
          variant: element[1],
        });
      }
    } else {
      if (parseInt(element[1]) > 0) {
        orderItems.push({
          productId: element[0],
          itemCount: element[1],
        });
      }
    }
  });

  const getProductDetailsById = async (productId) => {
    try {
      const result = await axios.get(`${server}/products/${productId}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      return result?.data?.product;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const fetchProductDetails = async () => {
      const fetchedProducts = await Promise.all(
        orderItems.map(async (item) => {
          const productDetails = await getProductDetailsById(item.productId);
          return {
            ...productDetails,
            itemCount: item.itemCount,
          };
        }),
      );
      setProducts(fetchedProducts);
    };

    fetchProductDetails();
  }, []);

  const calculateTotal = () =>
    products.reduce(
      (total, product) => total + product.itemCount * product.price,
      0,
    );

  return (
    <div>
      <div
        ref={componentRef}
        style={{
          padding: "20px",
          border: "1px solid #ccc",
          maxWidth: "600px",
          margin: "0 auto",
          background: "#f9f9f9",
          borderRadius: "8px",
          overflowY: "auto",
          overflowX: "hidden",
          maxHeight: "80vh",
        }}
      >
        <header style={{ textAlign: "center", marginBottom: "20px" }}>
          <h2 style={{ margin: "0", fontSize: "24px", fontWeight: "bold" }}>
            Agarwal Sales
          </h2>
          <p style={{ margin: "5px 0", fontSize: "16px", color: "#555" }}>
            7/A+B, Dnydeep Colony, Thorat Colony, Hingne Budrukh, Karve Nagar,
            Pune, Maharashtra 411052
          </p>
        </header>

        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Product
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Quantity
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Price
              </th>
              <th
                style={{
                  borderBottom: "1px solid #ddd",
                  padding: "8px",
                  textAlign: "left",
                }}
              >
                Total
              </th>
            </tr>
          </thead>
          <tbody>
            {products.map((product, index) => (
              <tr key={index}>
                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                  {product?.name}
                </td>
                <td style={{ padding: "8px", borderBottom: "1px solid #ddd" }}>
                  {product?.itemCount}
                </td>
                <td
                  style={{
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    whiteSpace: "nowrap",
                  }}
                >
                  {product?.price?.toFixed(2)} Rs
                </td>
                <td
                  style={{
                    padding: "8px",
                    borderBottom: "1px solid #ddd",
                    textAlign: "right",
                    whiteSpace: "nowrap",
                  }}
                >
                  {(product?.itemCount * product.price).toFixed(2)} Rs
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td
                colSpan="3"
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                Grand Total:
              </td>
              <td
                style={{
                  padding: "8px",
                  textAlign: "right",
                  fontWeight: "bold",
                }}
              >
                {calculateTotal().toFixed(2)}Rs
              </td>
            </tr>
          </tfoot>
        </table>

        <footer
          style={{
            textAlign: "center",
            marginTop: "30px",
            paddingTop: "20px",
            borderTop: "1px solid #ddd",
          }}
        >
          <p style={{ margin: "0", fontSize: "16px" }}>
            Thank you for your purchase!😊
          </p>
          <p style={{ margin: "5px 0", fontSize: "16px" }}>
            Order again at{" "}
            <a href="https://agarwalsales.com/" style={{ color: "#007bff" }}>
              agarwalsales
            </a>
          </p>
          <p style={{ margin: "5px 0", fontSize: "16px" }}>
            Enjoy shopping with us!
          </p>
        </footer>
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}
        >
          <ReactToPrint
            trigger={() => (
              <Button
                variant="contained"
                color="primary"
                startIcon={<PrintIcon />}
              >
                Download PDF
              </Button>
            )}
            content={() => componentRef.current}
            documentTitle="Order Invoice"
          />
        </div>
      </div>
    </div>
  );
};

export default PrintOrder;
