import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";

const DraggableSubcategory = ({
  row,
  handleDeleteSubcategory,
  handleStatus,
  setImageFile,
  setOldSubcategory,
  setEdit,
  handleOpen,
  isSequenceSubcategory,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row._id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    border: "1px solid transparent",
    cursor: "pointer",
    opacity: transform ? 0.5 : 1,
  };

  return (
    <TableRow
      ref={setNodeRef}
      style={style}
      hover
      role="checkbox"
      tabIndex={-1}
      key={row._id}
      {...attributes}
      {...listeners}
    >
      {isSequenceSubcategory && (
        <TableCell align="left" style={{ border: "1px solid transparent" }}>
          {row.sequence}
        </TableCell>
      )}
      {!isSequenceSubcategory && (
        <TableCell align="left" style={{ border: "1px solid transparent" }}>
          {row._id}
        </TableCell>
      )}
      <TableCell align="left" style={{ border: "1px solid transparent" }}>
        {row.name}
      </TableCell>
      <TableCell align="left" style={{ border: "1px solid transparent" }}>
        <img
          src={row.imageUrl === "noimage" ? DefaultImage : row.imageUrl}
          alt=""
          style={{ width: "50px" }}
        />
      </TableCell>
      <TableCell align="left" style={{ border: "1px solid transparent" }}>
        {format(new Date(row.createdAt), "dd MMMM yyyy - hh:mm a")}
      </TableCell>
      {isSequenceSubcategory && (
        <TableCell align="center" style={{ border: "1px solid transparent" }}>
          <IconButton
            onClick={() => handleDeleteSubcategory(row._id)}
            aria-label="delete"
          >
            <DeleteIcon />
          </IconButton>
        </TableCell>
      )}

      {!isSequenceSubcategory && (
        <TableCell align="left" style={{ border: "1px solid transparent" }}>
          <select
            defaultValue={row?.published}
            className="status-published"
            onChange={(e) => handleStatus(e.target.value, row._id)}
          >
            <option value={"true"}>Published</option>
            <option value={"false"}>Unpublished</option>
          </select>
        </TableCell>
      )}

      <TableCell align="center" style={{ border: "1px solid transparent" }}>
        <button
          className="edit-button"
          onClick={(e) => {
            e.preventDefault();
            setImageFile(row.imageUrl);
            setOldSubcategory(row);
            setEdit(true);
            handleOpen();
          }}
        >
          Edit
        </button>
      </TableCell>
    </TableRow>
  );
};

export default DraggableSubcategory;
