const Loader = ({ progress }) => {
  const loaderStyle = {
    width: "5em",
    height: "5em",
    background: "linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%)",
    animation: "spin 3s infinite",
    position: "relative",
    overflow: "hidden",
  };

  const beforeStyle = {
    content: '""',
    zIndex: -1,
    position: "absolute",
    inset: 0,
    background: "linear-gradient(-45deg, #fc00ff 0%, #00dbde 100%)",
    transform: "translate3d(0, 0, 0) scale(0.95)",
    filter: "blur(20px)",
  };

  const textStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: "#fff",
    fontSize: "1.2em",
    fontWeight: "bold",
    textAlign: "center",
    zIndex: 1,
  };

  return (
    <>
      <style>
        {`
          @keyframes spin {
            0% {
              transform: rotate(-45deg);
            }
            50% {
              transform: rotate(-360deg);
              border-radius: 50%;
            }
            100% {
              transform: rotate(-45deg);
            }
          }
        `}
      </style>
      <div style={loaderStyle}>
        <div style={beforeStyle} />
        <div style={textStyle}>{progress}%</div>
      </div>
    </>
  );
};

export default Loader;
