import { Box } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../server";
import Banner from "./Layout/Banner";
import Header from "./Layout/Header";
import ViewCategory from "./Layout/ViewCategory";

const Home = () => {
  const navigate = useNavigate();
  const [allCategories, setAllCategories] = useState([]);
  const [categoryBanner, setCategoryBanner] = useState([]);

  const [items, setItems] = useState();
  const [openSection, setOpenSections] = useState({});

  const getbanners = async () => {
    const result = await axios.get(`${server}/banners`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    // Get the banners after the first 8 objects
    const bannersAfterFirst8 = result.data.slice(8);

    // Filter the active banners from the sliced array
    const activeBanners = bannersAfterFirst8.filter((item) => item.active);

    setItems(activeBanners);

    // setItems(
    //   result.data.filter((item) => {
    //     return item.active;
    //   }),
    // );
    setCategoryBanner(result.data);
  };
  const getCategories = async () => {
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });

    setAllCategories(result?.data);
  };
  const getOpenSections = async () => {
    try {
      const result = await axios.get(`${server}/subcategories/section`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setOpenSections({
        section_1: result?.data?.section_1,
        section_2: result?.data?.section_2,
      });
    } catch (err) {}
    // console.log(result.data);
  };
  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    else getbanners() && getCategories() && getOpenSections();
  }, []);

  const [toLoad, setToLoad] = useState([0, 1]);

  const combineArrays = (banners, categories) => {
    let combined = [];
    // const maxLength = Math.max(banners.length, categories.length);

    for (let i = 0; i < 8; i++) {
      if (i < banners.length) {
        combined.push({ type: "banner", data: banners[i] });
      }
      if (i < categories.length) {
        combined.push({ type: "category", data: categories[i] });
      }
    }

    return combined;
  };

  const combinedArray = combineArrays(categoryBanner, allCategories);

  return (
    <Box sx={{ marginBottom: "100px" }}>
      <Header />

      <Banner
        min={0}
        max={10}
        items={items}
        index={1}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      {/* <CarouselHome /> */}

      {/* <ViewCategory index={1} toLoad={toLoad} setToLoad={setToLoad} /> */}

      {/* <div>
        {combinedArray.map((item, index) => {
          if (item.type === "banner") {
            return (
              <ViewBanner
                key={`banner-${index}`}
                bannerImg={item.data.image}
                catId={item.data.categoryId?._id}
                subcatId={item.data.subcategoryId?._id}
                prodId={item.data.productId?._id}
              />
            );
          } else if (item.type === "category") {
            console.log("item is ", item);
            return (
              <SubCategorysection
                key={`category-${item.data._id}`}
                categoryId={item.data._id}
              />
            );
          }
          return null;
        })}
      </div> */}
      <ViewCategory />

      {/* {allCategories.slice(0, 8).map((category, index) => {
        const bannerImg = latestCategoryImages[category._id];
        // console.log("banner Imges ", bannerImg);
        return (
          <ViewCategory
            key={category.id} // or any unique identifier
            catid={category._id}
            bannerImg={bannerImg}
            index={index}
            toLoad={toLoad}
            setToLoad={setToLoad}
          />
        );
      })} */}

      {/* {categoryBanner.slice(0, 8).map((banner, index) => {
        return (
          <ViewCategory
            prodId={banner.productId?._id}
            subcatId={banner.subcategoryId?._id}
            catid={banner.categoryId?._id}
            bannerImg={banner.image}
            index={index}
            toLoad={toLoad}
            setToLoad={setToLoad}
          />
        );
      })} */}

      {/* {allCategories.slice(0, 8).map((category, index) => {
        return <SubCategorysection categoryId={category?._id} />;
      })} */}
      {/* 
      <ViewCategory
        catid={"668bf2c277e448b08d514781"}
        bannerImg={
          "https://estore-v1.s3.ap-south-1.amazonaws.com/Noodles%2C_Pasta%2C_Vermicelli.png"
        }
        index={0}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* {allCategories.slice(0, 8).map((category, index) => (
        <ViewCategory
          key={category.id} // or any unique identifier
          catid={category._id}
          // toDisplay={subcatsToDisplay_16_9[0]}
          bannerImg={fileNames_16_9[index]}
          index={index}
          toLoad={toLoad}
          setToLoad={setToLoad}
        />
      ))} */}

      {/* <ViewCategory
        // catid="668bf18277e448b08d514736"
        toDisplay={subcatsToDisplay_16_9[0]}
        bannerImg={fileNames_16_9[2]}
        index={1}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <ViewCategory
        catid="668bf19077e448b08d51473a"
        toDisplay={subcatsToDisplay_16_9[1]}
        bannerImg={fileNames_16_9[9]}
        index={2}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="668bf26677e448b08d514779"
        toDisplay={subcatsToDisplay_16_9[2]}
        bannerImg={fileNames_16_9[1]}
        index={3}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <TrendingNow
        openSectionData={openSection.section_1}
        index={4}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <ViewCategory
        catid="668bf29677e448b08d51477d"
        toDisplay={subcatsToDisplay_16_9[3]}
        bannerImg={fileNames_16_9[7]}
        index={5}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="668bf2c277e448b08d514781"
        toDisplay={subcatsToDisplay_16_9[4]}
        bannerImg={fileNames_16_9[4]}
        index={6}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="668bf2f377e448b08d514785"
        toDisplay={subcatsToDisplay_16_9[5]}
        bannerImg={fileNames_16_9[6]}
        index={7}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <TrendingNow
        openSectionData={openSection.section_2}
        index={8}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="668bf37f77e448b08d51478d"
        toDisplay={subcatsToDisplay_16_9[6]}
        bannerImg={fileNames_16_9[5]}
        index={9}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <Banner
        min={10}
        max={20}
        items={items}
        index={10}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <ViewCategory
        catid="668bf3cf77e448b08d514791"
        toDisplay={subcatsToDisplay_16_9[7]}
        bannerImg={fileNames_16_9[3]}
        index={11}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />

      <ViewCategory
        catid="668bf3f777e448b08d514795"
        toDisplay={subcatsToDisplay_16_9[8]}
        bannerImg={fileNames_16_9[9]}
        index={12}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}
      {/* <hr style={{ height: "1px", borderTop: "1px solid #f00" }} /> */}
      {/* <SimilarProducts
        subCategoryId="668bf7edfb10cd66f6cb2df4"
        index={13}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="668bfa1bfb10cd66f6cb2e03"
        index={14}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="668bfa54fb10cd66f6cb2e0b"
        index={15}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="668bfa76fb10cd66f6cb2e11"
        index={16}
        toLoad={toLoad}
        setToLoad={setToLoad}
      />
      <SimilarProducts
        subCategoryId="668bfab0fb10cd66f6cb2e1d"
        index={17}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}

      {/* <SimilarProducts categoryid="6543bfd1f7e3ae87941ab66f" /> */}
      {/* the below view is for viewing products of category i.e it shows subcat cards */}
      {/* <ViewCategory catid="64919111c55b9dcf28c39354" /> */}
      {/* <ViewCategory catid="64cdd33cc472bb70b4de3786" /> */}
      {/* <CarouselHome /> */}
      {/* <ViewCategory catid="6543bfd1f7e3ae87941ab66f" /> */}
      {/* <Banner id = '64bfcc896bedd450788672c1'/> */}
      {/* <ViewCategory
        catid="668bf42777e448b08d514799"
        toDisplay={[]}
        bannerImg={fileNames_16_9[1]}
        index={18}
        toLoad={toLoad}
        setToLoad={setToLoad}
      /> */}
    </Box>
  );
};

export default Home;
