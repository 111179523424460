import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import server from "../../../server";
import DashboardContext from "../../Context/DashboardContext";
const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  display: "flex",
  "&:active": {
    "& .MuiSwitch-thumb": {
      width: 15,
    },
    "& .MuiSwitch-switchBase.Mui-checked": {
      transform: "translateX(9px)",
    },
  },
  "& .MuiSwitch-switchBase": {
    padding: 2,
    "&.Mui-checked": {
      transform: "translateX(12px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(["width"], {
      duration: 200,
    }),
  },
  "& .MuiSwitch-track": {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255,255,255,.35)"
        : "rgba(0,0,0,.25)",
    boxSizing: "border-box",
  },
}));

const NewProductForm = ({
  newProduct,
  setNewProduct,
  isEdit,
  add_data,
  setAdd_data,
  oldProduct,
  setname,
  newproductcategory,
  setcategory,
  setsubcategory,
  setmrpPrice,
  setprice,
  setstock,
  setdesc,
  setpublished,
  setbuyinglimit,
  setstocklimit,
  setbarcode,
  // setbrand,
}) => {
  const [selfShip, setSelfShip] = useState(false);
  const [categories, setCategories] = useState();
  const [catnames, setCatNames] = useState();
  const [subcatnames, setSubCatNames] = useState();
  const [orisubcatnames, setOriSubCatNames] = useState();
  const [subcategories, setSubCategories] = useState();

  const [defaultcat, setdefaultcat] = useState();
  const [defaultsubcat, setdefaultsubcat] = useState();
  // const [brandNames, setbrandnames] = useState();

  const [checked, setChecked] = useState(true);
  const context = useContext(DashboardContext);

  const getcatandsubcat = async () => {
    const result = await axios.get(`${server}/category`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setCategories(result.data);
    let arr = [];
    let defaulttemp;
    result.data.forEach((element, i) => {
      //this is for getting default value
      if (element._id === newProduct?.categoryId) {
        // console.log("got category " + element.name)
        setdefaultcat(i);
        setcategory(i);
        defaulttemp = i;
      }
      arr.push(element.name);
      if (i === result.data.length - 1) {
        // console.log(arr)
        setCatNames(arr);
      }
    });

    const subresult = await axios.get(`${server}/subcategories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    });
    // console.log(result.data)
    setSubCategories(subresult.data);
    setOriSubCatNames(subresult.data);
    setSubCatNames(subresult.data);

    let temparr = [];
    subresult.data.forEach((element, i) => {
      if (element._id === newProduct?.subcategoryId) {
        // console.log("got subcategory " + i)
        // setdefaultsubcat(i)
        setSubCatNames(
          subresult.data.filter(
            (item) => item.categoryId === newProduct?.categoryId,
          ),
        );
      }
      temparr.push(element.name);
      if (i === subresult.data.length - 1) {
        // console.log(arr)
        // setSubCatNames(temparr)
      }
    });

    let tempindex = 0;
    subresult.data.forEach((element, i) => {
      if (element.categoryId === newProduct?.categoryId) {
        // console.log("found sub of a cat called " + element.name)
        if (element._id === newProduct.subcategoryId) {
          // console.log("found index of my " + tempindex)
          setdefaultsubcat(tempindex);
          setsubcategory(i);
        }
        tempindex++;
      }
    });

    // const brands = await axios.get(`${server}/brands`, {
    //   headers: {
    //     Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    //   },
    // });

    // setbrandnames(brands.data);
  };

  useEffect(() => {
    getcatandsubcat();
  }, []);
  useEffect(() => {}, [
    categories,
    subcategories,
    catnames,
    subcatnames,
    defaultcat,
    defaultsubcat,
  ]);

  return (
    <>
      <div className="product-form">
        <div className="product-side-left">
          <div className="flex-div">
            {/* <p className="Customer-info">Name</p> */}

            <TextField
              // className="product-input"
              // placeholder="Product Name"
              label="Product Name"
              // disabled={true}
              // variant="outlined"
              style={{
                width: "100%",
                marginBottom: "5px",
                backgroundColor: "#EFF1F9",
              }}
              // value={newproductname}
              defaultValue={oldProduct?.name}
              onChange={(e) => setname(e.target.value)}
              MenuProps={{
                PaperProps: {
                  style: {
                    maxHeight: 224, // Limit height to 224px
                  },
                },
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            {catnames ? (
              <div className="select-multi">
                <FormControl
                  variant="outlined"
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                  }}
                  margin={"1"}
                >
                  <InputLabel id="test-select-label">Category</InputLabel>
                  <Select
                    sx={{
                      width: "100%",
                      backgroundColor: "#EFF1F9",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // value={}
                    defaultValue={
                      defaultcat || defaultcat === 0 ? defaultcat : null
                    }
                    label="Select Category"
                    // disabled={true}
                    onChange={(e) => {
                      setcategory(e.target.value);
                      setdefaultsubcat(0);
                      let changed = false;
                      subcategories.map((subcat, index) => {
                        if (
                          subcat.categoryId ===
                            categories[e.target.value]._id &&
                          !changed
                        ) {
                          setsubcategory(index);
                          changed = true;

                          // console.log("original index set to " + index)
                        }
                      });

                      //change subcatnames
                      categories.map((fullcat, i) => {
                        if (i === e.target.value) {
                          // console.log("the category is " + fullcat.name)
                          // console.log(orisubcatnames)
                          setSubCatNames(
                            orisubcatnames.filter(
                              (item) => item.categoryId === fullcat._id,
                            ),
                          );
                        }
                      });
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224, // Limit height to 224px
                        },
                      },
                    }}
                  >
                    {catnames &&
                      catnames.map((element, i) => {
                        return <MenuItem value={i}>{element}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <></>
            )}

            {subcatnames ? (
              <div className="select-multi">
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  margin={"1"}
                >
                  <InputLabel id="test-select-label">Sub Category</InputLabel>
                  <Select
                    // disabled={isEdit || (newproductcategory || newproductcategory === 0) ? false : true}
                    sx={{
                      width: "100%",
                      backgroundColor: "#EFF1F9",
                    }}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    // disabled={true}
                    // value={age}
                    defaultValue={
                      defaultsubcat || defaultsubcat === 0
                        ? defaultsubcat
                        : null
                    }
                    label="Select Category"
                    onChange={(e) => {
                      // console.log(subcatnames[e.target.value])
                      subcategories.map((subcat, index) => {
                        if (subcat.name === subcatnames[e.target.value].name) {
                          setsubcategory(index);
                          // console.log("original index set to " + index)
                        }
                      });
                    }}
                    MenuProps={{
                      PaperProps: {
                        style: {
                          maxHeight: 224, // Limit height to 224px
                        },
                      },
                    }}

                    // onChange={handleChange}
                  >
                    {subcatnames &&
                      subcatnames.map((element, i) => {
                        return <MenuItem value={i}>{element.name}</MenuItem>;
                      })}
                  </Select>
                </FormControl>
              </div>
            ) : (
              <></>
            )}
          </div>

          {/* {brandNames ? (
            <div className="select-multi">
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                margin="1"
              >
                <InputLabel id="test-select-label">Brands</InputLabel>
                <Select
                  sx={{
                    width: "100%",
                    // marginTop: "5px",
                    // marginLeft: "5px",
                    backgroundColor: "#EFF1F9",
                  }}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  // defaultValue={selectedcat}
                  label="Brands"
                  onChange={(e) => {
                    setbrand(e.target.value);
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224, // Limit height to 224px
                      },
                    },
                  }}
                >
                  {brandNames &&
                    brandNames.map((element, i) => (
                      <MenuItem value={element._id} key={element._id}>
                        {element.name}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>{" "}
            </div>
          ) : (
            <></>
          )} */}

          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div className="flex-div">
              <TextField
                // value={ newproductprice}
                // className="product-input1"
                label="MRP"
                defaultValue={oldProduct?.mrpPrice}
                // disabled={true}
                // variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#EFF1F9",
                }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  setmrpPrice(e.target.value);
                }}
              />
            </div>
            <div className="flex-div">
              <TextField
                // value={newproductstock}
                // className="product-input1"
                label="Price"
                defaultValue={oldProduct?.price}
                // disabled={true}
                // variant="outlined"
                style={{
                  width: "100%",
                  marginTop: "5px",
                  marginLeft: "5px",
                  backgroundColor: "#EFF1F9",
                }}
                // placeholder="Product MRP"
                onChange={(e) => {
                  setprice(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="product-side-right">
          <div className="flex-div">
            <TextField
              // value={newproductmrpPrice}
              // className="product-input1"
              label="Buying Limit"
              defaultValue={oldProduct?.buyinglimit || 0}
              // variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              // placeholder="Product MRP"
              onChange={(e) => {
                setbuyinglimit(e.target.value);
              }}
            />
          </div>

          <div className="flex-div">
            <TextField
              // value={newproductmrpPrice}
              // className="product-input1"
              label="Reserve Quantity"
              defaultValue={oldProduct?.stocklimit || 0}
              // variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              // placeholder="Product MRP"
              onChange={(e) => {
                setstocklimit(e.target.value);
              }}
            />
          </div>

          <div className="flex-div">
            <TextField
              // value={newproductmrpPrice}
              // className="product-input1"
              label="Current Stock"
              // disabled={true}
              defaultValue={oldProduct?.stock || 0}
              // variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              // placeholder="Product MRP"
              onChange={(e) => {
                setstock(e.target.value);
              }}
            />
          </div>
          <div className="flex-div">
            <TextField
              // value={newproductmrpPrice}
              // className="product-input1"
              label="BarCode"
              // disabled={true}
              defaultValue={oldProduct?.barcode || 0}
              // variant="outlined"
              style={{
                width: "100%",
                marginTop: "5px",
                marginLeft: "5px",
                backgroundColor: "#EFF1F9",
              }}
              // placeholder="Product MRP"
              onChange={(e) => {
                setbarcode(e.target.value);
              }}
            />
          </div>

          {!isEdit && (
            <div>
              <Typography>Product Desciption</Typography>
              <div className="flex-div">
                <textarea
                  // value={newproductdesc}
                  className="product-input desc-textarea"
                  placeholder="Your Text Goes Here"
                  onChange={(e) => {
                    setdesc(e.target.value);
                  }}
                />
              </div>
            </div>
          )}

          <div
            className="cash-on-delivery"
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            {/* <Stack direction="row" spacing={1} alignItems="center"> */}
            <p className="cash-text">Published</p>
            <AntSwitch
              defaultChecked={oldProduct ? oldProduct.published : false}
              onChange={(e) => {
                if (e.target.checked) setpublished("true");
                else setpublished("false");
              }}
              inputProps={{ "aria-label": "ant design" }}
            />
            {/* </Stack> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewProductForm;
