import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import OrderPlacedDone from "../../../assets/order-placed-done.png";
import AppReview from "../Review/AppReview";
import OrderFailedModal from "./Layout/OrderFailedModal";

const MainDivOrder = {
  width: "100%",
  marginBottom: "100px",
};

const OrderDoneText = {
  fontSize: "22px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 50px",
  color: "#181725",
  margin: "10px 0px",
};

const OrderPlacedText = {
  fontSize: "14px",
  fontWeight: "600",
  fontFamily: "quicksand",
  textAlign: "center",
  padding: "0px 50px",
  color: "#7C7C7C",
  margin: "10px 0px",
};

const ButtonCart = {
  backgroundColor: "#CD7F32",
  color: "#fff",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "500",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#D98B3B",
  },
};

const ButtonCartSecond = {
  backgroundColor: "#fff",
  color: "#181725",
  textTransform: "none",
  padding: "12px 10px",
  fontSize: "14px",
  fontWeight: "600",
  width: "100%",
  borderRadius: "19px",
  fontFamily: "quicksand",
  "&.MuiButtonBase-root:hover": {
    background: "#e9e8e8",
  },
};

const OrderAcceptedPage = () => {
  const [open, setOpen] = useState(false);
  const { status } = useParams();
  const { orderId } = useParams();
  // const [orderstatus , setStatus ] = useState(status)
  const navigate = useNavigate();
  const location = useLocation();
  const cartarr = location.state.cartarr;

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (!localStorage.getItem("user")) navigate("/");
    setOpen(true);
  }, []);

  return status === "1" ? (
    <>
      <Box sx={MainDivOrder}>
        <AppReview open={open} handleClose={handleClose} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            // marginTop: "100px",
            alignItems: "center",
          }}
        >
          <div style={{ width: "15%" }}></div>
          <img
            src={OrderPlacedDone}
            alt="Order Done"
            style={{
              width: "65%",
            }}
            // className="done-order-icon"
          />
          <div style={{ width: "20%" }}></div>
        </div>
        <Typography sx={OrderDoneText}>Your Order has been accepted</Typography>
        <Typography sx={OrderPlacedText}>
          Your items has been placed and is on its way to being processed
        </Typography>
        <Box sx={{ padding: "0px 10px", margin: "30px 0px 10px 0px" }}>
          {/* <Link to={`/trackorder/${orderId}`}> */}
          {/* <Button
            sx={ButtonCart}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/trackorder/${orderId}`, {
                state: { value: location?.state?.value },
              });
            }}
          >
            Track Order
          </Button> */}
          <Button
            sx={{ ...ButtonCart, marginBottom: "10px" }}
            onClick={(e) => {
              e.preventDefault();
              navigate("/printorder", { state: { cartarr: cartarr } });
            }}
          >
            Print Order
          </Button>

          <Button
            sx={ButtonCart}
            onClick={(e) => {
              e.preventDefault();
              navigate(`/trackorder/${orderId}`, {
                state: { value: location?.state?.value },
              });
            }}
          >
            Track Order
          </Button>
          {/* </Link> */}
        </Box>
        <Box sx={{ padding: "0px 10px", margin: "10px 0px" }}>
          <Link to="/">
            <Button sx={ButtonCartSecond}>Back to home</Button>
          </Link>
        </Box>
      </Box>
    </>
  ) : (
    <OrderFailedModal />
  );
};

export default OrderAcceptedPage;
