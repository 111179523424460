import PropTypes from "prop-types";
import { useContext, useEffect, useState } from "react";
import DefaultImage from "../../../../assets/defaultimg.jpg";

import DeleteIcon from "@mui/icons-material/Delete";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import SearchIcon from "@mui/icons-material/Search";
import {
  alpha,
  Box,
  Button,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import axios from "axios";
import { Link, useLocation } from "react-router-dom";
import server from "../../../server";
// import axios from "axios";
import ClearIcon from "@mui/icons-material/Clear";
import DashboardContext from "../../Context/DashboardContext";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "rwId",
    numeric: false,
    disablePadding: true,
    label: "uniqueId",
  },
  {
    id: "image",
    numeric: false,
    disablePadding: true,
    label: "Img",
  },
  {
    id: "productName",
    numeric: true,
    disablePadding: false,
    label: "Product Name",
  },
  {
    id: "category",
    numeric: true,
    disablePadding: false,
    label: "Category",
  },
  {
    id: "subcategory",
    numeric: true,
    disablePadding: false,
    label: "Sub Category",
  },
  {
    id: "buyinglimit",
    numeric: true,
    disablePadding: false,
    label: "Buying Limit",
  },

  {
    id: "unitPrice",
    numeric: true,
    disablePadding: false,
    label: "MRP Price",
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
  },
  {
    id: "stocklimit",
    numeric: true,
    disablePadding: false,
    label: "Reserve Quantity",
  },
  {
    id: "instock",
    numeric: true,
    disablePadding: false,
    label: "In-Stock",
  },
  {
    id: "action",
    numeric: true,
    disablePadding: false,
    label: "Action",
  },
  {
    id: "status",
    numeric: true,
    disablePadding: false,
    label: "Status",
  },
];

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead
      style={{ borderTop: "1px solid #E1E2E9", padding: "0", margin: "0" }}
    >
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const [filterInput, setFilterInput] = useState("");
  const [filter, setFilter] = useState({});
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const {
    selectedCategory,
    selectedSubcategory,
    setSelectedCategory,
    setSelectedSubcategory,
  } = props;
  const location = useLocation();

  const handlesearch = async (e) => {
    console.log("filterinput ", filterInput);
    props.setLoading(true);
    let filteredProducts = props.oriprodData;
    const filterValue = filterInput?.toLowerCase();

    if (![undefined, null, ""].includes(filterValue)) {
      filteredProducts = filteredProducts.filter((product) => {
        const productName = product.name?.toLowerCase();
        const categoryName = product.categoryId?.name?.toLowerCase();
        const subCategoryName = product.subCategoryId?.name?.toLowerCase();

        return (
          (productName && productName.includes(filterValue)) ||
          (categoryName && categoryName.includes(filterValue)) ||
          (subCategoryName && subCategoryName.includes(filterValue))
        );
      });
    }

    props.setIProducts(filteredProducts);
    props.setLoading(false);
  };

  // const handlesearch = async (e) => {
  //   props.setLoading(true);
  //   let filteredProducts = props.oriprodData;

  //   Object.keys(filterInput).forEach((key) => {
  //     const filterValue = filterInput[key].toLowerCase();

  //     filteredProducts = [undefined, null, ""].includes(filterValue)
  //       ? filteredProducts
  //       : filteredProducts.filter((product) => {
  //           const productValue =
  //             key === "name"
  //               ? product[key]?.toLowerCase()
  //               : product[key + "Id"]?.name?.toLowerCase();
  //           return productValue && productValue.includes(filterValue);
  //         });
  //   });

  //   props.setIProducts(filteredProducts);
  //   props.setLoading(false);

  //   // if (e.target.value != "") {
  //   //   // console.log(e.target);
  //   //   // console.log(props.oriorderData);
  //   //   const searchField = () => {
  //   //     if (e.target.name == "category") {
  //   //       return ["categoryId", "name"];
  //   //     } else if (e.target.name == "subCategory") {
  //   //       return ["subCategoryId", "name"];
  //   //     } else {
  //   //       return ["name", false];
  //   //     }
  //   //   };
  //   //   const [field1, field2] = searchField();
  //   //   const filteredRows = !field2
  //   //     ? await props.oriprodData.filter((row) => {
  //   //         return row?.[field1]
  //   //           ?.toLowerCase()
  //   //           ?.includes(e.target.value.toLowerCase());
  //   //       })
  //   //     : await props.oriprodData.filter((row) => {
  //   //         return row?.[field1]?.[field2]
  //   //           ?.toLowerCase()
  //   //           ?.includes(e.target.value.toLowerCase());
  //   //       });
  //   //   props.setIProducts(filteredRows);
  //   // } else {
  //   //   // setRows(filteredRows);
  //   //   // console.log(filteredRows);
  //   //   props.setIProducts(props.oriprodData);
  // };

  // const handleFilterChange = (e) => {
  //   const { name, value } = e.target;
  //   console.log("name and value ", name, value);
  //   setFilterInput((prevFilterInput) => ({
  //     ...prevFilterInput,
  //     [name]: value,
  //   }));
  // };

  const handleFilterChange = (e) => {
    const { value } = e.target;
    setFilterInput(value);
  };

  //   useEffect(() => {
  //     console.log("filterInput", filterInput);
  //   }, [filterInput]);

  useEffect(() => {
    const fetchAllCategories = async () => {
      const result = await axios.get(`${server}/category`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });
      setCategories(result.data);
    };
    fetchAllCategories();
  }, []);

  useEffect(() => {
    if (selectedCategory) {
      const fetchSubCategories = async () => {
        try {
          const result = await axios.get(
            `${server}/subcategory/find/${selectedCategory}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            },
          );
          setSubcategories(result.data);
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };
      fetchSubCategories();
    }
  }, [selectedCategory]);

  const handleCategoryChange = (event) => {
    const selectedCategoryId = event.target.value;
    setSelectedCategory(selectedCategoryId);
    setSelectedSubcategory("");
    const selectedCategoryObj = categories.find(
      (category) => category._id === selectedCategoryId,
    );
    setFilterInput(selectedCategoryObj?.name);
  };

  const handleSubcategoryChange = (event) => {
    const selectedSubcategoryId = event.target.value;
    setSelectedSubcategory(selectedSubcategoryId);
    const selectedSubcategoryObj = subcategories.find(
      (subcategory) => subcategory._id === selectedSubcategoryId,
    );
    setFilterInput(selectedSubcategoryObj?.name);
  };

  useEffect(() => {
    const { inventorySelectedCategory, inventorySelectedSubCategory } =
      location.state || {};
    if (inventorySelectedCategory && inventorySelectedSubCategory) {
      setSelectedCategory(inventorySelectedCategory);
      setSelectedSubcategory(inventorySelectedSubCategory);
      const selectedCategoryObj = categories.find(
        (category) => category._id === inventorySelectedCategory,
      );
      setFilterInput(selectedCategoryObj?.name);

      const getSubCategories = async () => {
        try {
          const result = await axios.get(
            `${server}/subcategory/find/${inventorySelectedCategory}`,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
              },
            },
          );
          setSubcategories(result.data);
          if (result.data.length > 0) {
            const selectedSubcategoryObj = result.data.find(
              (subcategory) => subcategory._id === inventorySelectedSubCategory,
            );
            setSelectedSubcategory(selectedSubcategoryObj._id);
            setFilterInput(selectedSubcategoryObj?.name);
          }
          return result?.data;
        } catch (error) {
          console.error("Error fetching subcategories:", error);
        }
      };
      getSubCategories();
    }
  }, []);

  useEffect(() => {
    if (filterInput) {
      handlesearch();
    }
  }, [filterInput]);

  const handleClearCategory = () => {
    setSelectedCategory("");
    setSelectedSubcategory("");
    console.log("clear");
  };

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity,
            ),
        }),
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div>
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: "1 1 100%" }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: "1 1 100%" }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Inventory Items
          </Typography>
        )}
      </div>

      <div>
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        ) : (
          <div style={{ display: "flex", gap: 8 }}>
            {/* <Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search by Category"
                  onChange={handleFilterChange}
                  name="category"
                />
              </div>
            </Tooltip> */}
            <Tooltip title="Clear selected category">
              <IconButton
                style={{ marginRight: 8 }}
                onClick={handleClearCategory}
                disabled={!selectedCategory} // Disable button when no category is selected
              >
                <ClearIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Select a category to filter">
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
              >
                <InputLabel>Search by Category</InputLabel>
                <Select
                  value={selectedCategory}
                  onChange={handleCategoryChange}
                  label="Search by Category"
                  sx={{ textAlign: "left" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224, // Limit height to 224px
                      },
                    },
                  }}
                >
                  {categories.map((category) => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>

            {/* <Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField
                  style={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search by Sub Category"
                  onChange={handleFilterChange}
                  name="subCategory"
                />
              </div>
            </Tooltip> */}
            <Tooltip title="Select a subcategory to filter">
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: "300px" }}
                disabled={!selectedCategory}
              >
                <InputLabel>Search by Sub Category</InputLabel>
                <Select
                  value={selectedSubcategory}
                  onChange={handleSubcategoryChange}
                  // labelId="demo-simple-select-label"
                  // id="demo-simple-select"
                  label="Search by Sub Category"
                  sx={{ textAlign: "left" }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 224, // Limit height to 224px
                      },
                    },
                  }}
                >
                  {subcategories.map((subcategory) => (
                    <MenuItem key={subcategory._id} value={subcategory._id}>
                      {subcategory.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Tooltip>
            <Tooltip>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <TextField
                  style={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search by Product Name"
                  onChange={handleFilterChange}
                  name="name"
                /> */}
                <TextField
                  style={{
                    width: "300px",
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment>
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  placeholder="Search "
                  onChange={handleFilterChange}
                  name="search"
                />
              </div>
            </Tooltip>
            <Button variant="contained" onClick={handlesearch}>
              Search
            </Button>
          </div>
        )}
      </div>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

const InventoryTable = ({ products, fetchproducts }) => {
  const context = useContext(DashboardContext);
  const { setLoading } = context;
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [dense, setDense] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const location = useLocation();

  //iproducts is the prod currently being displayed
  const [iProducts, setIProducts] = useState();
  const [oriprodData, setoriprodData] = useState(products);
  const [displayProducts, setDisplayProducts] = useState([]);

  const theme = useTheme();
  const [personName, setPersonName] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value,
    );
  };

  // console.log(products);

  const requestOptions = {
    headers: {
      method: "GET",
      Authorization: `Bearer ${localStorage.getItem("access")}`,
    },
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    // if (event.target.checked) {
    //   const newSelected = rows.map((n) => n.name);
    //   setSelected(newSelected);
    //   return;
    // }
    // setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 25));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - iProducts?.length) : 0;

  useEffect(() => {
    const _ =
      rowsPerPage > 0
        ? iProducts?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        : iProducts;

    // console.log(_);

    _ && setDisplayProducts(_);
  }, [iProducts, page, rowsPerPage]);

  // useEffect(() => {
  // 	const _ =
  // 		rowsPerPage > 0
  // 			? iProducts?.slice(
  // 					page * rowsPerPage,
  // 					page * rowsPerPage + rowsPerPage
  // 			  )
  // 			: iProducts;

  // 	// console.log(_);

  // 	_ && setDisplayProducts(_);
  // }, []);

  useEffect(() => {
    setIProducts(products);
  }, [products, selectedCategory]);

  const [status, setStatus] = useState({});

  useEffect(() => {
    let status = {};
    iProducts?.map((item, index) => {
      status = { ...status, [index]: item.status };
    });
    // console.log(status);
    setStatus({ ...status });
  }, [iProducts]);
  // useEffect(()=>{
  //   console.log(status);
  // },[status])

  const handleStatus = async (value, id) => {
    // const token = `Bearer ${localStorage.getItem("access")}`
    setLoading(true);
    // const newProduct = { "status": value }

    // navigate("/content")
    //api to change status
    // console.log(id)
    try {
      const updatestatus = await axios.put(
        `${server}/products/update/${id}`,
        {
          published: value,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        },
      );
      // console.log(updatestatus)
      // functionchange()
    } catch (error) {
      console.log(error);
    }

    setLoading(false);

    // fetchproducts();
    // setStatus({ ...status, [key]: value })
  };

  return (
    <div className="order-table-main">
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            products={iProducts}
            setIProducts={setIProducts}
            oriprodData={products}
            setLoading={setLoading}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            selectedSubcategory={selectedSubcategory}
            setSelectedSubcategory={setSelectedSubcategory}
          />

          <TableContainer style={{ padding: "0px 20px" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={iProducts?.length}
              />
              <TableBody>
                {/* .filter((crop) => crop.crops[0] === cropFilter) */}
                {/* {console.log(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage,
									iProducts?.slice(
										page * rowsPerPage,
										page * rowsPerPage + rowsPerPage
									)
								)} */}
                {displayProducts.map((row, index) => {
                  const isItemSelected = isSelected(row.name);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  //  console.log(row?.image.split(",")[0]);
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.name)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                      style={{
                        border: "1px solid transparent",
                      }}
                    >
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {/* {console.log(row?.rwId)} */}
                        {row?.rwId}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <img
                          alt={DefaultImage}
                          src={
                            row.imageUrl === "noimage"
                              ? DefaultImage
                              : row.imageUrl
                          }
                          style={{ width: "50px" }}
                        />
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.categoryId?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.subCategoryId?.name}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.buyinglimit}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.mrpPrice}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row?.price}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {/* {row?.price} */}
                        {row?.stocklimit}
                      </TableCell>
                      <TableCell
                        align="left"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        {row.stock}
                        {row?.stock < row?.stocklimit && "- Out Of Stock"}
                      </TableCell>
                      <TableCell
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <select
                          defaultValue={row?.published}
                          className="status-published"
                          onChange={(e) =>
                            handleStatus(e.target.value, row._id)
                          }
                        >
                          <option value={"true"}>Published</option>
                          <option value={"false"}>Unpublished</option>
                        </select>
                      </TableCell>
                      <TableCell
                        padding="none"
                        align="center"
                        style={{
                          border: "1px solid transparent",
                        }}
                      >
                        <Link
                          to="/editproduct"
                          state={{
                            id: row._id,
                            data: row,
                            isEdit: true,
                            selectedCategory: selectedCategory,
                            selectedSubcategory: selectedSubcategory,
                          }}
                        >
                          <button className="edit-button">Edit</button>
                        </Link>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[25, 50]}
            component="div"
            count={iProducts?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={TablePaginationActions}
          />
        </Paper>
      </Box>
    </div>
  );
};

export default InventoryTable;
